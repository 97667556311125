import React, { useContext, useState } from "react";
import "./OurTeam.scss";
import { employeeData } from "../../Constants/EmployeeData";
import { BsArrowReturnRight } from "react-icons/bs";
import { HiArrowUpRight } from "react-icons/hi2";

import jalaj from "../../assets/images/home/teams/jalaj.jpg";
import gautam from "../../assets/images/home/teams/gaitam.jpg";

import { useNavigate } from "react-router-dom";

export default function OurTeam() {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/career");
    window.scrollTo(0, 0); // Ensures the page scrolls to the top.
  };

  return (
    <div className="MYteams">
      <div className="top-botton">
        <p className="iicon">
          {" "}
          <BsArrowReturnRight /> Our Team
        </p>
      </div>
      <div className="empHeading">
        <p className="details-emp">
          We are passionate about creating visually stunning and functional
          solutions that communicate effectively.
        </p>
        <div className="ceocheck">
          <div className="jalaj">
            <p className="detials">
              lorem lorem lore lorem lore Lorem ipsum dolor sit.
            </p>
            <div className="imgoverdiv">
              <img src={jalaj} alt="" className="jajajimg" />
            </div>
            <div className="about">
              <div className="name">MR. Jalaj Tiwari </div>
              <div className="postion ">
                <b>CEO (IIM SAMBALPUR)</b>{" "}
              </div>
            </div>
          </div>
          <div className="jalaj gautam">
            <p className="detials">
              lorem lorem lore lorem lore Lorem ipsum dolor sit.
            </p>
            <div className="imgoverdiv">
              <img src={gautam} alt="" className="jajajimg" />
            </div>
            <div className="about">
              <div className="name">Gautam Singh Chauhan </div>
              <div className="postion">
                <b>CEO (IIM INDORE)</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="employes">
        {employeeData.map((data, id) => {
          return (
            <div key={id} className="jalaj">
              <img src={data.imgSrc} alt={data.name} className="jajajimg" />
              <div className="about">
                <div className="name">{data.name}</div>
                <div className="postion">{data.position}</div>
              </div>
              <p className="detials">{data.details}</p>
            </div>
          );
        })}
      </div>
      <div className="buttons">
        <button
          onClick={(event) => {
            if (event.ctrlKey || event.metaKey || event.button === 1) {
              const newTabUrl = "/career";
              window.open(newTabUrl, "_blank");
            } else {
              window.scrollTo(0, 0);
              navigate("/career");
            }
          }}
        >
          Be the part of eHawkers <HiArrowUpRight />
        </button>
      </div>
    </div>
  );
}
