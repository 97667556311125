import React, { useState } from "react";
import "./Home.scss";
import topGreen from "../../assets/images/home/bgbbg.jpg";
import leftblue from "../../assets/images/home/Ellipse 3.png";
import leftpink from "../../assets/images/home/Ellipse 4 (1).png";
import rightyellow from "../../assets/images/home/Ellipse 5.png";
import botmbg from "../../assets/images/home/ks.png";
import tpbg from "../../assets/images/home/topbg.png";
import Header from "../../compenents/Header/Header";
import OurPortPolio from "../../compenents/OurPortPolio/OurPortPolio";
import Ourclient from "../../compenents/Ourclient/Ourclient";
import AditForm from "../../compenents/AditForm/AditForm";
import OurTeam from "../../compenents/OurTeam/OurTeam";
import HomeService from "../../compenents/HomeService/HomeService";
import ContactForEvery from "../../compenents/ContactForEvery/ContactForEvery";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
//don
export default function Home() {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    message: "",
  });

  const handleNext = (e) => {
    if (step < 3) {
      if (step === 0 && formData.name.length <= 3) {
        toast.warn("Name must be more than 3 characters!", {
          position: "top-right",
          autoClose: 3000,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        return;
      }

      if (step === 1 && !/^\d{10}$/.test(formData.number)) {
        toast.warn("Number must be exactly 10 digits!", {
          position: "top-right",
          autoClose: 3000,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        return;
      }

      if (step === 2 && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        toast.warn("Please enter a valid email address!", {
          position: "top-right",
          autoClose: 3000,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        return;
      }

      if (step === 3 && formData.message.length <= 5) {
        toast.warn("Message must be more than 5 characters!", {
          position: "top-right",
          autoClose: 3000,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        return;
      }
      setStep(step + 1);
    } else {
      // Handle form submission logic here
      handleSubmit(e);
      setFormData({ name: "", number: "", email: "", message: "" });
      setStep(0);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const config = {
      url: process.env.REACT_APP_BASE_URL + `api/createContact`,
      method: "post",
      data: {
        name: formData?.name,
        phoneNumber: formData?.number,
        email: formData?.email,
        message: formData?.message,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.status == true) {
          console.log("dfghj", response?.data);
          toast.success("Contact Details Get Successfully", {
            position: "top-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        }
        if (response?.data?.status == false) {
          if (response?.data?.response_code == 500) {
            toast.error("Problem With Data Fetching", {
              position: "top-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else if (response?.data?.response_code == 404) {
            toast.error("Problem With Data Fetching", {
              position: "top-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.response_code == 401) {
          toast.error("Your token is expired. Please login again.", {
            position: "top-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.warn("Problem With Data Fetching", {
            position: "top-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      });
  };

  return (
    <div className="Home">
      <div className="section-one">
        {/* <div className="yellowji"></div> */}
        <img src={topGreen} alt="" className="greenji" />
        {/* <img src={topYellow} alt="" className="yellowji" /> */}
        <div className="home-header">
          <Header />
        </div>

        <div className="heroTopDiv">
          <h1 className="digtaltext">Digital Solution</h1>
          <h1 className="drivestext">
            that drives <span>Revenue</span>
          </h1>

          <p>
            We're a full-stack digital agency, which means we can provide a wide
            range of <br /> services in technology and marketing to help you
            grow your business.
          </p>

          <div className="searchbar">
            <div className="inner-searchbar">
              <div className={`innn input-step step-${step}`}>
                {step === 0 && (
                  <>
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter your Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </>
                )}
                {step === 1 && (
                  <>
                    <input
                      type="text"
                      name="number"
                      placeholder="Enter your Phone Number"
                      value={formData.number}
                      onChange={handleChange}
                    />
                  </>
                )}
                {step === 2 && (
                  <>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your Email Address"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </>
                )}
                {step === 3 && (
                  <>
                    <input
                      name="message"
                      type="message"
                      placeholder="Enter your Message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </>
                )}
              </div>
              <button onClick={(event) => handleNext(event)}>
                {step === 3 ? "Submit" : "Next ->"}
              </button>
            </div>
          </div>
        </div>

        {/*  */}
        <div className="section-tow-container">
          <div className="sectionTwo">
            <div className="sec-bg-img">
              <img src={tpbg} alt="" className="topbg" />
              <img src={botmbg} alt="" className="buttombg" />
            </div>

            <div className="ourhead">
              <h2>
                Our <span className="bluetext">business</span>{" "}
                <div className="line">
                  <div className="top"></div>
                  <div className="center blueCenter"></div>
                  <div className="bot"></div>
                </div>{" "}
                is to <span className="greentext">grow </span>{" "}
                <div className="line">
                  <div className="top"></div>
                  <div className="center"></div>
                  <div className="bot"></div>
                </div>{" "}
                yours
              </h2>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="bgimages">
          <div className="bg1"></div>

          <img src={leftblue} alt="" className="bg2" />
          <img src={leftpink} alt="" className="bg3" />
          <img src={rightyellow} alt="" className="bg4" />
        </div>
      </div>

      <div className="ourserive">
        <img src={leftpink} alt="" className="ourservicebgbg" />
        <HomeService />
      </div>

      <div className="PortFolio">
        <OurPortPolio />
      </div>

      <div className="ourClient">
        <Ourclient />
      </div>

      <div className="AditForm">
        <AditForm />
      </div>
      <div className="ourteams">
        <OurTeam />
      </div>

      <div className="LinePart">
        <div className="CheckLinePart"></div>
      </div>

      <div className="JoinForms">
        <ContactForEvery />
      </div>

      <ToastContainer />
    </div>
  );
}
