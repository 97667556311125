import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import "./Ourclient.scss";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import leftcolor from "../../assets/images/home/clientlogos/Group 33.png";
import rightcolor from "../../assets/images/home/clientlogos/Group 34.png";
import { latestLogos } from "../../Constants/Clientlogose";

const row1 = latestLogos.slice(0, 10);
const row2 = latestLogos.slice(10, 20);
const row3 = latestLogos.slice(20, 30);
const row4 = latestLogos.slice(30, 40);

export default function Ourclient() {
  const [showPlus, setShowPlus] = useState(false); // Control "+" visibility

  const { number } = useSpring({
    from: { number: 0 }, // Always starts at 0
    to: 300, // Animates to 300
    config: { duration: 1000 }, // 5-second animation
    onRest: () => setShowPlus(true), // Show "+" after animation completes
  });

  const { growth } = useSpring({
    from: { growth: 0 },
    to: 152,
    config: { duration: 1000 }, // 5-second animation
    onRest: () => setShowPlus(true), // Show "+" after animation completes
  });

  const { percet } = useSpring({
    from: { percet: 0 },
    to: 99.5,
    config: { duration: 1000 }, // 5-second animation
    onRest: () => setShowPlus(true), // Show "+" after animation completes
  });

  const settingsHome = {
    infinite: true,
    speed: 5000,
    slidesToShow: 8,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0,
    pauseOnHover: false,
    rtl: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: true,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 530,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          cssEase: "linear",
        },
      },
    ],
  };

  const settingsHome2 = {
    infinite: true,
    speed: 6000,
    slidesToShow: 8,
    slidesToScroll: -1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0,
    pauseOnHover: false,
    rtl: true,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          // slidesToScroll: 1,
          infinite: true,
          rtl: true, // Ensure RTL in responsive settings
          cssEase: "linear",
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 7,
          // slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          rtl: true, // Ensure RTL in responsive settings
          cssEase: "linear",
        },
      },
      {
        breakpoint: 530,
        settings: {
          slidesToShow: 3,
          // slidesToScroll: 1,
          infinite: true,
          rtl: true, // Ensure RTL in responsive settings
          cssEase: "linear",
        },
      },
    ],
  };

  return (
    <div className="Ourclient">
      <div className="bgImg">
        <img src={leftcolor} alt="left color" className="leftcolor" />
        <img src={rightcolor} alt="right color" className="rightcolor" />
      </div>

      <h1 className="heading">Our Client</h1>
      <p>
        We are trusted by the top-tier companies, We will talk about your
        problem and the <br /> best way to solve it even if you don’t choose us.
      </p>
      <div className="overlayNumber">
        <div className="numbers">
          <div className="number">
            <animated.h1>{number.to((n) => `${~~n} +`)}</animated.h1>
            <p>Successful Projects</p>
          </div>
          <div className="number">
            <animated.h1>{growth.to((n) => `${~~n} +`)}</animated.h1>
            <p>Customers and growing</p>
          </div>
          <div className="number">
            <animated.h1>{percet.to((n) => `${n.toFixed(1)} +`)}</animated.h1>
            <p>Success Rate</p>
          </div>
        </div>
      </div>

      <div>
        <div className="clientslogo">
          <Slider {...settingsHome}>
            {row1.map((item, id) => (
              <img key={id} src={item} alt="client logo" className="logo" />
            ))}
          </Slider>
          <Slider {...settingsHome2}>
            {row2.map((item, id) => (
              <img key={id} src={item} alt="client logo" className="logo" />
            ))}
          </Slider>
          <Slider {...settingsHome}>
            {row3.map((item, id) => (
              <img key={id} src={item} alt="client logo" className="logo" />
            ))}
          </Slider>
          <Slider {...settingsHome2}>
            {row4.map((item, id) => (
              <img key={id} src={item} alt="client logo" className="logo" />
            ))}
          </Slider>
          {/* <Slider {...settingsHome}>
            {row5.map((item, id) => (
              <img key={id} src={item} alt="client logo" className="logo" />
            ))}
          </Slider> */}
        </div>
      </div>
    </div>
  );
}
