import React, { useEffect, useRef } from "react";
import "./PerfomanceWorkflow.scss";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { ScrollSmoother } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const perfomanceData = [
  {
    phase: "Phase 01",
    image: "https://via.placeholder.com/150",
    title: "REVIEW HISTORICAL DATA AND DEFINE PERFORMANCE GOALS",
    content:
      "Everything we do at eHawkers is tied to return on investment. During our initial brainstorming and ideation sessions, we will define your PPC goals, analyze any historical data you may have, and craft a clear plan that highlights where and how we can improve your ROI. By analyzing cost per click, conversion rate, cost per acquisition, and revenue, you can rest assured that our services will provide the necessary value. We can also tie our successful conversions back into our optimization, resulting in a higher converting campaign moving forward.",
    WorkInvolved: ["Review hostorial Data", "Goal Analysis", "Brainstroming"],
  },
  {
    phase: "Phase 02",

    image: "https://via.placeholder.com/150",
    title: "CREATE A TAILORED MEDIA PLAN",
    content:
      "Whether you want to run ads on Meta and Google, our team will do research into your preferred audience and develop a PPC plan that will reach them where they are. From top-notch copy to implementing creative elements, we will work in a collaborative environment to pair your customized strategy with high-end creative your audience will love!",
    WorkInvolved: ["Review hostorial Data", "Goal Analysis", "Brainstroming"],
  },
  {
    phase: "Phase 03",

    image: "https://via.placeholder.com/150",
    title: "CUSTOMIZED KEYWORD & AUDIENCE TARGETING",
    content:
      "Without the right keywords and targeting, you can waste hundreds of thousands of bucks a year. When you work with us, we’ll use our proven methodology and process to maximize each bid in your campaign. If required, we’ll start by cleaning up your PPC accounts, removing poorly performing keywords, and retargeting your investment if already done.",
    WorkInvolved: ["Review hostorial Data", "Goal Analysis", "Brainstroming"],
  },
  {
    phase: "Phase 04",

    image: "https://via.placeholder.com/150",
    title: "IMPLEMENT TRACKING",
    content:
      "Our team is well-versed in what you need to run a successful PPC campaign. We will set up all of the back-end data tracking methods needed to ensure we obtain accurate data, including your conversion volume, CPA, conversion rate, ad spend, CTR, and average CPC. ",
    WorkInvolved: ["Review hostorial Data", "Goal Analysis", "Brainstroming"],
  },
  {
    phase: "Phase 05",

    image: "https://via.placeholder.com/150",
    title: "DEVELOP & MONITOR BIDDING STRATEGY",
    content:
      "The right bid is critical. We’ll use advanced software and your company’s insights to make sure that you never overpay. Once we are up and running, eHawkers will monitor, report, and refine our strategy to ensure that you’re making the most of your investment. ",
    WorkInvolved: ["Review hostorial Data", "Goal Analysis", "Brainstroming"],
  },
  {
    phase: "Phase 06",

    image: "https://via.placeholder.com/150",
    title: "DATA ANALYSIS, REPORT PREPARATION, & STRATEGY REFINEMENT",
    content:
      "Once your ads are up and running, our team will continually monitor your progress and analyze your tracking data. You’ll receive a monthly report of your media performance, including any new strategies and next month’s action plan. We will suggest any necessary updated bid strategies, settings, keyword mapping, or A/B tests. We will also make recommendations for the next steps to grow your PPC success, including adding audiences or exploring different platforms. ",
    WorkInvolved: ["Review hostorial Data", "Goal Analysis", "Brainstroming"],
  },
];

export default function PerfomanceWorkflow() {
  useGSAP(() => {
    if (window.innerWidth < 768) return;
    gsap.to(".card-section", {
      x: "-70%",
      scrollTrigger: {
        trigger: ".Performance-Marketing-Workflow",
        start: "top 0%",
        end: "bottom 10%",
        scrub: true,
        pin: true,
      },
    });
  });

  useGSAP(() => {
    if (window.innerWidth < 768) return;

    gsap.to(".whiteline", {
      x: "-70%",
      scrollTrigger: {
        trigger: ".whiteline",
        start: "bottom 90%",
        end: "bottom -10%",
        scrub: true,
      },
    });
  });

  return (
    <div className="Performance-Marketing-Workflow">
      <div className="bgbgbg"></div>
      <div className="bgbgbg1"></div>
      <h1 className="Performance-hd">Performance Marketing Workflow</h1>
      <p className="p-paragraph">
        Here is the overview of a medium sized project. Lorem ipsum dolor sit
        amet, <br /> consectetur adipiscing elit. Donec et ipsum et lorem
        pharetra commodo ac vitae eros.
      </p>

      <div className="cardOuter">
        <div className="card-section">
          {perfomanceData.map((item, id) => {
            return (
              <div className="cardcontainers" key={id}>
                <div className="card-item">
                  <div className="bgwhite"></div>
                  {/* <img src={item.image} alt={item.title} /> */}
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUfXOdMAfUqxym1vj6rCpjmwKMC-AegGiEHg&s"
                    alt=""
                  />
                  <div className="information">
                    <h3>{item.title}</h3>
                    <p className="item-details">{item.content}</p>
                    <div className="work-involved">
                      <h5>Work Involved</h5>
                      <div className="work-container">
                        {item.WorkInvolved.map((work, index) => (
                          <p className="wk-type" key={index}>
                            {work}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="phase">{item.phase}</div>
              </div>
            );
          })}
        </div>
        {/* <div className="whiteline">
          <div className="lineOuter">
            <div className="line"></div>
            <button>Phase 01</button>
            <div className="line"></div>
          </div>
          <div className="lineOuter">
            <div className="line"></div>
            <button>Phase 02</button>
            <div className="line"></div>
          </div>
          <div className="lineOuter">
            <div className="line"></div>
            <button>Phase 03</button>
            <div className="line"></div>
          </div>
          <div className="lineOuter">
            <div className="line pdd" style={{ paddingLeft: "22rem" }}></div>
            <button>Phase 04</button>
            <div className="line"></div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
