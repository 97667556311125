import React from "react";
import "./AboutStrategy.scss";
import { FaRegCheckCircle } from "react-icons/fa";

export default function AboutStrategy({ title, span, strategyData }) {
  return (
    <div className="AboutStrategy">
      <h1 className="strategy-auther">
        {title} <span>{span}</span> <br /> can provide you?
      </h1>

      <div className="stragy-card-container">
        {strategyData.map((card, id) => {
          return (
            <div className="strategy-card " key={id}>
              <div className="card-img">
                {/* <img src={card.image} alt={card.title} /> */}
                <img
                  className="rounded-5" 
                  src="https://digitalazadi.com/wp-content/uploads/2024/05/Social-Media-Branding-Strategy.jpg"
                  alt=""
                />
              </div>
              <div className="strategy-overlay">
                <h2 className="number" key={id}>
                  {(id + 1).toString().padStart(2, "0")}.
                </h2>

                <h3 className="strategy-heading">{card.heading}</h3>
                <p
                  className="strategy-details"
                  dangerouslySetInnerHTML={{ __html: card.details }}
                ></p>

                <ul>
                  {card.specification.map((spec, specIndex) => (
                    <li key={specIndex}>
                      <FaRegCheckCircle /> &nbsp;&nbsp;{spec}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
