import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Header.scss";
import logo from "../../assets/images/header/Group 11.png";
// import mylogo from "../../assets/images/home/mylogo.png";
import mylogo from "../../assets/images/home/footer/Dark - H - T 1.png";

export default function Header() {
  const [active, setActive] = useState("Home");
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control side menu
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const location = useLocation();

  const menuItems = {
    Home: "/",
    About: "/about",
    Services: "/service",
    Contact: "/contact",
    Careers: "/career",
    Portfolio: "/Portfolio",
    "Resource Center": "/Blog",
  };

  const handleActive = (item) => {
    navigate(menuItems[item]);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    const activeItem = Object.keys(menuItems).find(
      (key) => menuItems[key] === currentPath
    );
    setActive(activeItem || "");
  }, [location.pathname]);

  return (
    <div className="Header">
      <div
        className="logo"
        onClick={(event) => {
          if (event.ctrlKey || event.metaKey || event.button === 1) {
            const newTabUrl = `/`;
            window.open(newTabUrl, "_blank");
          } else {
            window.scrollTo(0, 0);
            navigate("/");
          }
        }}
      >
        <img
          src={mylogo}
          alt="Newehawkers Logo"
          style={{ cursor: "pointer" }}
        />
      </div>

      <button
        className="hamburger"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        aria-label="Toggle menu"
      >
        ☰
      </button>
      <div
        className="hamburgerClose"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        aria-label="Toggle menu"
        style={{ display: isMenuOpen ? "block" : "none", zIndex: "9999999" }}
      >
        <i class="bi bi-x-circle text-danger"></i>
      </div>

      <div className={`innerHeader ${isMenuOpen ? "open" : ""}`} ref={menuRef}>
        <ul className="underline">
          {Object.keys(menuItems).map((item) => (
            <li
              key={item}
              className={active === item ? "active" : ""}
              onClick={() => handleActive(item)}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
