import React from "react";
import "./NewPortfolio.scss";
import mylogo from "../../assets/images/home/footer/Dark - H - T 1.png";
import iphone from "../../assets/images/newPortfolio/iphone/iphone.png";
import notch from "../../assets/images/newPortfolio/iphone/notch.png";

import withoutname from "../../assets/images/header/Group 11.png";

import { newPortData } from "../../Constants/NewPortData";
import { useNavigate } from "react-router-dom";

export default function NewPortfolio() {
  const pages = ["Page 1", "Page 2", "Page 3", "Page 4"];
  const navigate = useNavigate();
  return (
    <div className="NewPortfolio">
      <div className=" portTopHead text-center ">
        <button
          className="position top-0 z-2 text-danger bg-transparent border-0 m-4"
          onClick={() => navigate("/")}
        >
          <i class="bi bi-arrow-left-circle fs-5"></i>
        </button>
        <img src={withoutname} alt="" className="mb-5" />
        <h1 className="hd">
          EHAWKERS <br />
          MARKETING
        </h1>
        <p className="tp-para ">Tech folio</p>
      </div>
      {newPortData.map((data, id) => (
        <div
          className={`portfolio-section ${
            (id + 1) % 3 === 0 ? "highlight" : ""
          }`}
          key={id}
        >
          <div className={`overlays ${(id + 1) % 3 === 0 ? "highligh" : ""}`}>
            <div className="left-content">
              <div className="logo">
                <img src={data.logo} alt={data.alt} />
              </div>
              <div className="titles">
                <h4 className="Port-title">{data.title}</h4>
                <p className="port-para">{data.description}</p>
                <a
                  className="export-link"
                  onClick={(event) => {
                    if (event.ctrlKey || event.metaKey || event.button === 1) {
                      const newTabUrl = data?.link;
                      window.open(newTabUrl, "_blank");
                    } else {
                      const newTabUrl = data?.link;
                      window.open(newTabUrl, "_blank");
                    }
                  }}
                >
                  Explore Project
                </a>
                <h4 className="techhead">technologies</h4>
              </div>
              <div className="tech-logos">
                {data.technology.map((tech, index) => (
                  <img src={tech} alt="" key={index} />
                ))}
              </div>
              <div className="text-start mt-4">
                <img src={withoutname} alt="" />
              </div>
            </div>
            {/* right */}
            <div className="bg" style={{ background: data.bg }}></div>
            <div className="right-content">
              <div className="imgall-con">
                {data?.image1?.map((pic, index) => (
                  <div className="imgall" key={index}>
                    <img className="imgone" src={pic} alt="image " />
                  </div>
                ))}
              </div>
              <div className="image2-container">
                {data?.image2?.map((pic, index) => (
                  <div className="img2" key={index}>
                    <img className="imgtwo" src={pic} alt="image " />
                  </div>
                ))}
              </div>

              {/* for i phone code  */}

              {/* <div className="mobile shadow">
                <div className="image-container">
                  <div className="notch">
                    <img src={notch} alt="" />
                  </div>
                  <div className="imgcontainer">
                    <img src={data.fullimg} alt="" />
                  </div>
                </div>
                <div className="div shadow">
                  <img src={iphone} alt="" />
                </div>
              </div>
              <a
                className="export-link btn btn-danger"
                onClick={(event) => {
                  if (event.ctrlKey || event.metaKey || event.button === 1) {
                    const newTabUrl = data?.link;
                    window.open(newTabUrl, "_blank");
                  } else {
                    const newTabUrl = data?.link;
                    window.open(newTabUrl, "_blank");
                  }
                }}
              >
                Explore Project
              </a> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
