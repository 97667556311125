import React from "react";
import "./MediaAbout.scss";

import img1 from "../../assets/images/mediaservice/i1.png";
import img2 from "../../assets/images/mediaservice/i2.png";
import img3 from "../../assets/images/mediaservice/i3.png";

const mediaaboutdata = [
  {
    title: "Global Event Coverage",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nec ex fermentum, maximus eros varius, consequat nisl. Duis sit amet scelerisque nisi. Cras venenatis.",
    image: img1,
  },
  {
    title: "Media Support",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nec ex fermentum, maximus eros varius, consequat nisl. Duis sit amet scelerisque nisi.",
    image: img2,
  },
  {
    title: "Public Relation",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nec ex fermentum, maximus eros varius, consequat nisl. Duis sit amet scelerisque nisi.",
    image: "",
  },
  {
    title: "Real-time social media updates",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. consequat nisl. Duis sit amet scelerisque nisi. Cras venenatis",
    image: img3,
  },
  {
    title: "Social Media Specific Content",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nec ex fermentum, maximus eros varius, consequat nisl. Duis sit amet scelerisque nisi.",
    image: "",
  },
];

export default function MediaAbout() {
  return (
    <div className="MediaAbout">
      <div className="top-info">
        <div className="left-firewall-content">
          <div className="f-head">Another cool punchline</div>
          <div className="f-head">about Media</div>
        </div>
        <div className="right-firewall-content">
          <p className="fire-paragraph">
            Unlock your brand’s potential with strategic digital <br />{" "}
            marketing. With our expertise, every click brings you <br /> closer
            to your goals.
          </p>
        </div>
      </div>
      <div className="aboutSection">
        {mediaaboutdata.map((media, index) => (
          <div key={index} className="media-media">
            <div className="media-overlay-info">
              <h3 className="media-title">{media.title}</h3>
              <p className="media-para">{media.description}</p>
            </div>
            <img src={media?.image} />
            <div className="allbg">
              <div className="bginner">
                <div className="bgbig"></div>
                <div className="bgcenter"></div>
                <div className="bgsmall"></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
