import React from "react";
import "./NewPage.scss";
import { useNavigate } from "react-router-dom";

import { projects } from "../../Constants/TechProjects";

export default function NewPage() {
  const navigate = useNavigate();
  return (
    <div className="mainContainerPort">
      <div className="portInfoHeader">
        <div className="innerHead">
          <button
            className="position top-0 z-2 text-danger bg-transparent border-0 m-4"
            onClick={() => navigate("/")}
          >
            <i class="bi bi-arrow-left-circle fs-5"></i>
          </button>
          <h1>Tech/Portfolio</h1>
        </div>
        {projects?.map((project, index) => (
          <div key={index}>
            <div className="innerHead xsx">
              <h1>
                E-hawkers <br />
                X <br />
                {project?.name}
              </h1>
            </div>

            <div className="containers">
              <div className="content">
                {/* Header Section */}
                <div className="header">
                  <h1>{project?.name}</h1>
                  <p>{project?.description}</p>
                </div>

                {/* Features Section */}
                <div className="features">
                  <h2>Website Features & Function</h2>
                  <ul>
                    {project?.features?.map((feature, i) => (
                      <li key={i}>{feature}</li>
                    ))}
                  </ul>
                </div>
                {/* project overview  */}
                <div className="customer-feedback">
                  <h2>Project Overview</h2>
                  <blockquote>{project?.feedback}</blockquote>
                </div>

                {/* Tech Stack Section */}
                <div className="tech-stack">
                  <h2>Tech Stack</h2>
                  <div className="grid">
                    {project?.techStack?.map((tech, i) => (
                      <span key={i} className="tech-item">
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>

                {/* Customer Feedback Section */}

                <div className="customer-feedback">
                  <h2>Customer Feedback</h2>
                  <blockquote>{project?.feedback}</blockquote>
                  <p className="author">{project?.author}</p>
                </div>

                {/* Contact Section */}
                <div className="contact-section">
                  <button
                    className="contact-button border btn btn-primary"
                    onClick={() => {
                      navigate(project?.contactLink);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    Contact Us
                  </button>
                  <a href={project?.projectLink}>
                    <h3 className="btn border shadow btn-primary">
                      Explore Project
                    </h3>
                  </a>
                </div>
              </div>
              {/* background */}
              <div className="bg" style={{ background: project.bg }}></div>
              {/* Image Section */}
              <div className="image-container">
                {project?.images?.map((src, i) => (
                  <img key={i} src={src} alt={`${project?.name} - ${i + 1}`} />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
