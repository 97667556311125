import React from "react";
import "./ChallengeAccept.scss";
import { FaRegCheckCircle } from "react-icons/fa";

// img
import img1 from "../../assets/images/service/challenge/v1.mp4";
import img2 from "../../assets/images/service/challenge/v2.mp4";
import img3 from "../../assets/images/service/challenge/v3.mp4";
import img4 from "../../assets/images/service/challenge/v4.mp4";
import computer from "../../assets/images/service/challenge/computer.png";
import boxess from "../../assets/images/service/challenge/box.png";

export default function ChallengeAccept() {
  const blogPosts = [
    {
      shadow: "0 4px 166px rgba(66, 133, 244, 0.3)",
      img: img2,
      title: "My Website isn’t getting enough traffic",
      icon: "🚦",
      content:
        "Without consistent site traffic, you’re missing out on valuable visitors, leads, and revenue. Thankfully, custom SEO solutions can get you back on track by boosting your presence in search engine results so more people can find and visit your site.",
      points: [
        "Boost your presence in search results",
        "Capture targeted site traffic",
        "Turn site visitors into leads and customers",
      ],
    },
    {
      shadow: "0 4px 166px  rgba(255, 103, 38 , 0.3)",

      img: img1,
      icon: "📈 ",
      title: "My CPL from digital ad campaigns is too high ",
      content:
        " Tired of wasting money on ad campaigns? Our paid ad experts can help streamline your bidding strategy and refine ad targeting so you get the maximum impact from your ads.",
      points: [
        "Refine ad targeting",
        "Engage your audience where they browse online",
        "Maximize paid ad budget",
      ],
    },
    {
      shadow: "0 4px 166px rgba(255, 139, 38, 0.3)",
      img: img4,
      icon: "🐁",
      title: "My website isn’t generating enough leads ",
      content:
        "Struggling to get qualified leads in your pipeline? Turn up the dial on your lead generation with digital marketing plans, tailored to your goals and budget.",
      points: [
        "Fill up your lead pipeline",
        "Reach your most valuable audience",
        "Maximize conversion opportunities",
      ],
      mt: "20px",
    },
    {
      shadow: "0 4px 166px rgba(79, 234, 121, 0.3)",
      img: img3,
      icon: "⚽",
      title:
        " We’re unable to hit our Sales Qualified Leads and revenue goals ",
      content:
        "Not happy with lead and revenue numbers? Attract and close more SQLs and watch sales soar with personalized revenue marketing.",
      points: [
        "Power up qualified leads",
        "Keep your sales team busy",
        "Drive more revenue from online marketing",
      ],
      // margin: "1rem",
    },
  ];

  return (
    <div className="ChallengeAccept">
      <div className="boxSection">
        {/* <h1 className="heading">Challenge Accepted</h1> */}
        <img src={boxess} alt="" />
        {/* <div className="upperdiv">
          <div className="round">
            <div className="boxOuter">
              {[...Array(268)].map((_, index) => (
                <div key={index} className="boxes"></div>
              ))}
            </div>
          </div>
        </div> */}
      </div>

      <div className="content-container">
        {blogPosts.map((post, index) => (
          <div
            key={index}
            className={`inner-section ${
              index % 2 === 0 ? "left-right" : "right-left"
            }`}
            style={{ marginTop: post.margin }}
          >
            <div className="image">
              <video autoPlay loop muted className="video">
                <source src={post?.img} type="video/mp4" className="rou" />
                Your browser does not support the video tag.
              </video>
            </div>

            <div className="content" style={{ marginTop: post.mt }}>
              <h2>
                {post.title} <span>{post.icon}</span>
              </h2>
              <p>{post.content}</p>
              <ul>
                {post.points.map((point, index) => (
                  <li key={index}>
                    {" "}
                    <FaRegCheckCircle size={15} className="icons" /> &nbsp;{" "}
                    {point}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
