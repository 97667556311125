import React from "react";
import "./FAQ.scss";
import { FaAngleDown } from "react-icons/fa6";

const accordionData = [
  {
    id: "one",
    title: "Do you have specialties or focus on a specific industry ?",
    content:
      "Our team works with clients from a variety of B2B and B2C industries. As an Indian company, we geek out over outdoor retailers and e-commerce, but you’ll also find us doing deep dives into SaaS and manufacturing industries. No matter your industry, we’ve crafted a digital marketing strategy for it!",
  },
  {
    id: "two",
    title: "Who will be my day-to-day contact ?",
    content:
      "At eHawkers, we do things a little differently. From the early stages of the sales process, you’ll be talking to an experienced eHawkers team member who will be your primary point of contact — no pushy salesperson in sight. Once you sign with us, you’ll work with the same Hawker, and you’ll also meet the two to three other digital marketing strategists on their team who will be building and executing your strategy.  Also, there will be a group of dedicated experts in graphic design, content writing, video production, and other important aspects, whom you can suggest iterations at any point in time.",
  },
  {
    id: "three",
    title: "How many clients does each team member manage?",
    content:
      "We view companies that work with us not as clients but as partners. For that reason, each team member partners with a smaller group of clients: – we average 3 to 4 clients per team member. We’re here to help you reach your marketing goals, and we want you to feel like you’re our top priority — because you are.",
  },
  {
    id: "four",
    title: "Do you use freelancers or is your team in-house?",
    content:
      "You'll collaborate with a team of seasoned full-time professionals, each boasting decades of experience in their respective fields. While our core capabilities are done in-house, if you’d like to explore any additional creative genre, we have a network of trusted partners we bring in to work on your project. Working with these partners on a project basis helps us work within your budget while still providing you with top creative talent for your marketing.",
  },
  {
    id: "five",
    title: "How do you communicate with clients during the relationship?",
    content:
      "We’ll be here when you need us, just a text or a mail or a call away. We’ll always respond in 5 hours or less… even on Sunday!! We’ll also be sending you strategies, insights, and reporting throughout the month. Apart from this, we also have a set call time from the start — either biweekly or monthly, depending on your preferences and level of service.",
  },
  {
    id: "six",
    title: "Do you lock clients into long-term contracts?",
    content:
      "Not at all! All contracts are on a month-to-month basis. However, we’ve worked with many of our clients for years as partners to their internal marketing team — we’re still creating campaigns for the very first client who signed up for the agency years ago!",
  },
  // {
  //   id: "seven",
  //   title: "What makes eHawkers different from other agencies?",
  //   content:
  //     "Simply put, we’re here to be true partners with your team. Unlike other agencies, who may drop a templated strategy on your lap and walk away, we’re deeply invested in your success. We work until we have a complete understanding of your business and your unique needs, and we’ll do whatever it takes to help you reach your marketing goals,. We’ve also had many clients tell us that we’re more than just a vendor; our meetings have been called “the best of the week” (yes, that’s an actual quote from a client). Don’t expect a stuffy or boring call filled with dense business jargon, either. We’re here to make marketing fun again and to create some serious impact while we’re doing it!",
  // },
];
export default function FAQ() {
  return (
    <div className="FAQ">
      <h1>
        Frequently <br />
        Asked Questions
      </h1>

      <div className="accordion" id="accordionExample">
        {accordionData.map((item, index) => (
          <div className="accordion-item " key={item.id}>
            <h2 className="accordion-header " id={`heading${item.id}`}>
              <button
                className={`accordion-button custom-accordion-button  ${
                  index === 0 ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${item.id}`}
                aria-expanded={index === 0 ? "true" : "false"}
                aria-controls={`collapse${item.id}`}
              >
                {item.title}
              </button>
            </h2>
            <div
              id={`collapse${item.id}`}
              className={`accordion-collapse collapse  ${
                index === 0 ? "show" : ""
              }`}
              aria-labelledby={`heading${item.id}`}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body ">
                <p>{item.content}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
