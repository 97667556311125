import logo1 from "../assets/images/home/clientlogos/logo ehawkers/01lic.webp";
import logo2 from "../assets/images/home/clientlogos/logo ehawkers/02iim.jpeg";
import logo3 from "../assets/images/home/clientlogos/logo ehawkers/03vedanta.jpg";
import logo4 from "../assets/images/home/clientlogos/logo ehawkers/04jawed.png";
import logo5 from "../assets/images/home/clientlogos/logo ehawkers/05first cry.png";
import logo6 from "../assets/images/home/clientlogos/logo ehawkers/06phonepe.png";
import logo7 from "../assets/images/home/clientlogos/logo ehawkers/07bni.png";
import logo8 from "../assets/images/home/clientlogos/logo ehawkers/08fenton.png";
import logo9 from "../assets/images/home/clientlogos/logo ehawkers/09aurbo.png";
import logo10 from "../assets/images/home/clientlogos/logo ehawkers/10hr.png";
import logo11 from "../assets/images/home/clientlogos/logo ehawkers/11tempus.png";
import logo12 from "../assets/images/home/clientlogos/logo ehawkers/12rajgarh.png";
import logo13 from "../assets/images/home/clientlogos/logo ehawkers/13kanha (2).png";
import logo14 from "../assets/images/home/clientlogos/logo ehawkers/14tafi.jpeg";
import logo15 from "../assets/images/home/clientlogos/logo ehawkers/15glycon ind.avif";
import logo16 from "../assets/images/home/clientlogos/logo ehawkers/16 property suchma.jpg";
import logo17 from "../assets/images/home/clientlogos/logo ehawkers/17glp.png";
import logo18 from "../assets/images/home/clientlogos/logo ehawkers/18 bharti.png";
import logo19 from "../assets/images/home/clientlogos/logo ehawkers/19isheros.png";
import logo21 from "../assets/images/home/clientlogos/logo ehawkers/21revoral.png";
import logo22 from "../assets/images/home/clientlogos/logo ehawkers/22divyta.png";
import logo24 from "../assets/images/home/clientlogos/logo ehawkers/24thinking bridge.png";
import logo26 from "../assets/images/home/clientlogos/logo ehawkers/26 akash uniforms.png";
import logo27 from "../assets/images/home/clientlogos/logo ehawkers/27 RAER.jpg";
import logo28 from "../assets/images/home/clientlogos/logo ehawkers/28 GROWSKY INFOTECH.jpg";
import logo29 from "../assets/images/home/clientlogos/logo ehawkers/29jet.png";
import logo31 from "../assets/images/home/clientlogos/logo ehawkers/31omeTech Infra logo -3.png";
import logo32 from "../assets/images/home/clientlogos/logo ehawkers/32 unipride.jpg";
import logo33 from "../assets/images/home/clientlogos/logo ehawkers/33yanci_logo c.png";
import logo34 from "../assets/images/home/clientlogos/logo ehawkers/34Sugarrush.png";
import logo35 from "../assets/images/home/clientlogos/logo ehawkers/35 freedom wealth.png";
import logo36 from "../assets/images/home/clientlogos/logo ehawkers/36GUILTFREE.jpg";
import logo37 from "../assets/images/home/clientlogos/logo ehawkers/37real guruji.png";
import logo38 from "../assets/images/home/clientlogos/logo ehawkers/38roopantaran-.png";
import logo39 from "../assets/images/home/clientlogos/logo ehawkers/39FLYING RABBIT TM mark.webp";
import logo40 from "../assets/images/home/clientlogos/logo ehawkers/40 INTRED.png";
import logo41 from "../assets/images/home/clientlogos/logo ehawkers/41century.jpeg";
import logo43 from "../assets/images/home/clientlogos/logo ehawkers/43wedding-station-logo.png";
import logo44 from "../assets/images/home/clientlogos/logo ehawkers/44Untitled-1.png";
import logo47 from "../assets/images/home/clientlogos/logo ehawkers/47 AMRIT FARMS.jpg";
import logo48 from "../assets/images/home/clientlogos/logo ehawkers/48 MYEKISAN.jpg";

// letest...
import latestlogo1 from "../assets/images/home/clientlogos/latestlogo/1.png";
import latestlogo2 from "../assets/images/home/clientlogos/latestlogo/2.png";
import latestlogo3 from "../assets/images/home/clientlogos/latestlogo/3.png";
import latestlogo4 from "../assets/images/home/clientlogos/latestlogo/4.png";
import latestlogo5 from "../assets/images/home/clientlogos/latestlogo/5.png";
import latestlogo6 from "../assets/images/home/clientlogos/latestlogo/6.png";
import latestlogo7 from "../assets/images/home/clientlogos/latestlogo/7.png";
import latestlogo8 from "../assets/images/home/clientlogos/latestlogo/8.png";
import latestlogo9 from "../assets/images/home/clientlogos/latestlogo/9.png";
import latestlogo10 from "../assets/images/home/clientlogos/latestlogo/10.png";
import latestlogo11 from "../assets/images/home/clientlogos/latestlogo/11.png";
import latestlogo12 from "../assets/images/home/clientlogos/latestlogo/12.png";
import latestlogo13 from "../assets/images/home/clientlogos/latestlogo/13.png";
import latestlogo14 from "../assets/images/home/clientlogos/latestlogo/14.png";
import latestlogo15 from "../assets/images/home/clientlogos/latestlogo/15.png";
import latestlogo16 from "../assets/images/home/clientlogos/latestlogo/16.png";
import latestlogo17 from "../assets/images/home/clientlogos/latestlogo/17.png";
import latestlogo18 from "../assets/images/home/clientlogos/latestlogo/18.png";
import latestlogo19 from "../assets/images/home/clientlogos/latestlogo/19.png";
import latestlogo20 from "../assets/images/home/clientlogos/latestlogo/20.png";
import latestlogo21 from "../assets/images/home/clientlogos/latestlogo/21.png";
import latestlogo22 from "../assets/images/home/clientlogos/latestlogo/22.png";
import latestlogo23 from "../assets/images/home/clientlogos/latestlogo/23.png";
import latestlogo24 from "../assets/images/home/clientlogos/latestlogo/24.png";
import latestlogo25 from "../assets/images/home/clientlogos/latestlogo/25.png";
import latestlogo26 from "../assets/images/home/clientlogos/latestlogo/26.png";
import latestlogo27 from "../assets/images/home/clientlogos/latestlogo/27.png";
import latestlogo28 from "../assets/images/home/clientlogos/latestlogo/28.png";
import latestlogo29 from "../assets/images/home/clientlogos/latestlogo/29.png";
import latestlogo30 from "../assets/images/home/clientlogos/latestlogo/30.png";
import latestlogo31 from "../assets/images/home/clientlogos/latestlogo/31.png";
import latestlogo32 from "../assets/images/home/clientlogos/latestlogo/32.png";
import latestlogo33 from "../assets/images/home/clientlogos/latestlogo/33.png";
import latestlogo34 from "../assets/images/home/clientlogos/latestlogo/34.png";
import latestlogo35 from "../assets/images/home/clientlogos/latestlogo/35.png";
import latestlogo36 from "../assets/images/home/clientlogos/latestlogo/36.png";
import latestlogo37 from "../assets/images/home/clientlogos/latestlogo/37.png";
import latestlogo38 from "../assets/images/home/clientlogos/latestlogo/38.png";
import latestlogo39 from "../assets/images/home/clientlogos/latestlogo/39.png";
import latestlogo40 from "../assets/images/home/clientlogos/latestlogo/40.png";

export const Clientlogose = [
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  logo11,
  logo12,
  logo13,
  logo14,
  logo15,
  logo16,
  logo17,
  logo18,
  logo19,
  logo21,
  logo22,
  logo24,
  logo26,
  logo27,
  logo28,
  logo29,
  logo31,
  logo32,
  logo33,
  logo34,
  logo35,
  logo36,
  logo37,
  logo38,
  logo39,
  logo40,
  logo41,
  logo43,
  logo44,
  logo47,
  logo48,
];

// expo Clientlogose;
export const latestLogos = [
  latestlogo1,
  latestlogo2,
  latestlogo3,
  latestlogo4,
  latestlogo5,
  latestlogo6,
  latestlogo7,
  latestlogo8,
  latestlogo9,
  latestlogo10,
  latestlogo11,
  latestlogo12,
  latestlogo13,
  latestlogo14,
  latestlogo15,
  latestlogo16,
  latestlogo17,
  latestlogo18,
  latestlogo19,
  latestlogo20,
  latestlogo21,
  latestlogo22,
  latestlogo23,
  latestlogo24,
  latestlogo25,
  latestlogo26,
  latestlogo27,
  latestlogo28,
  latestlogo29,
  latestlogo30,
  latestlogo31,
  latestlogo32,
  latestlogo33,
  latestlogo34,
  latestlogo35,
  latestlogo36,
  latestlogo37,
  latestlogo38,
  latestlogo39,
  latestlogo40,
];

export const ReverseLatestLogos = [
  latestlogo27,
  latestlogo26,
  latestlogo25,
  latestlogo18,
  latestlogo17,
  latestlogo16,
  latestlogo15,
  latestlogo14,
  latestlogo13,
  latestlogo12,
  latestlogo11,
  latestlogo10,
  latestlogo9,
  latestlogo40,
  latestlogo39,
  latestlogo38,
  latestlogo37,
  latestlogo36,
  latestlogo35,
  latestlogo34,
  latestlogo33,
  latestlogo32,
  latestlogo31,
  latestlogo30,
  latestlogo29,
  latestlogo28,
  latestlogo8,
  latestlogo7,
  latestlogo6,
  latestlogo5,
  latestlogo24,
  latestlogo23,
  latestlogo22,
  latestlogo21,
  latestlogo20,
  latestlogo19,
  latestlogo4,
  latestlogo3,
  latestlogo2,
  latestlogo1,
];
