import React from "react";
import "./Footer.scss";
import { FaInstagram, FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaBehance } from "react-icons/fa";
import logo from "../../assets/images/home/footer/Dark - H - T 1.png";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <div className="line-one">
        <div className="borders"></div>
        <div className="line-two"></div>
        <div className="line-three"></div>
      </div>

      <div className="bgimg2"></div>
      <div className="footer-container-main">
        <div className="logo-section">
          <img
            src={logo}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={(event) => {
              if (event.ctrlKey || event.metaKey || event.button === 1) {
                const newTabUrl = "/";
                window.open(newTabUrl, "_blank");
              } else {
                window.scrollTo(0, 0);
                navigate("/");
              }
            }}
          />
        </div>

        <div className="inner-main">
          <div className="main-content">
            <div className="links-section">
              <div className="social-links">
                <div className="direct-links">
                  <div className="media-icons">
                    <p className="icon-heading">Connect With Us</p>
                    <div className="social-icons">
                      <a
                        href="https://www.instagram.com/ehawkers_/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaInstagram className="icons" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/93088422/admin/dashboard/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLinkedin className="icons" />
                      </a>
                      <a
                        href="https://www.facebook.com/profile.php?id=61555330409891  "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebook className="icons" />
                      </a>
                      <a
                        href="https://www.youtube.com/@e-hawkersmarketing"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaYoutube className="icons" />
                      </a>
                      <a
                        href="https://www.behance.net/ehawkers"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaBehance className="icons" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="links">
                  <p
                    onClick={(event) => {
                      if (
                        event.ctrlKey ||
                        event.metaKey ||
                        event.button === 1
                      ) {
                        const newTabUrl = "/contact";
                        window.open(newTabUrl, "_blank");
                      } else {
                        window.scrollTo(0, 0);
                        navigate("/contact");
                      }
                    }}
                  >
                    Contact
                  </p>
                  <p>Terms</p>
                  <p>Privacy Policy</p>
                </div>
              </div>

              <div className="quick-links dkdk">
                <h4>Quick Links</h4>
                <div className="linkListOfFooter">
                  <div className="firstList">
                    <button
                      className="quick-link"
                      onClick={(event) => {
                        if (
                          event.ctrlKey ||
                          event.metaKey ||
                          event.button === 1
                        ) {
                          // If Ctrl (or Command on macOS) or middle mouse button is clicked

                          const newTabUrl = "/";
                          window.open(newTabUrl, "_blank");
                        } else {
                          // Default behavior for normal left-click
                          window.scrollTo(0, 0);
                          navigate("/");
                        }
                      }}
                    >
                      Home
                    </button>
                    <button
                      className="quick-link"
                      onClick={(event) => {
                        if (
                          event.ctrlKey ||
                          event.metaKey ||
                          event.button === 1
                        ) {
                          // If Ctrl (or Command on macOS) or middle mouse button is clicked

                          const newTabUrl = "/service";
                          window.open(newTabUrl, "_blank");
                        } else {
                          // Default behavior for normal left-click
                          window.scrollTo(0, 0);
                          navigate("/service");
                        }
                      }}
                    >
                      Services
                    </button>
                    <button
                      className="quick-link"
                      onClick={(event) => {
                        if (
                          event.ctrlKey ||
                          event.metaKey ||
                          event.button === 1
                        ) {
                          // If Ctrl (or Command on macOS) or middle mouse button is clicked

                          const newTabUrl = "/contact";
                          window.open(newTabUrl, "_blank");
                        } else {
                          // Default behavior for normal left-click
                          window.scrollTo(0, 0);
                          navigate("/contact");
                        }
                      }}
                    >
                      Contact
                    </button>
                    <button
                      className="quick-link"
                      onClick={(event) => {
                        if (
                          event.ctrlKey ||
                          event.metaKey ||
                          event.button === 1
                        ) {
                          // If Ctrl (or Command on macOS) or middle mouse button is clicked

                          const newTabUrl = "/about";
                          window.open(newTabUrl, "_blank");
                        } else {
                          // Default behavior for normal left-click
                          window.scrollTo(0, 0);
                          navigate("/about");
                        }
                      }}
                    >
                      About
                    </button>
                    <button
                      className="quick-link"
                      onClick={(event) => {
                        if (
                          event.ctrlKey ||
                          event.metaKey ||
                          event.button === 1
                        ) {
                          // If Ctrl (or Command on macOS) or middle mouse button is clicked

                          const newTabUrl = "/career";
                          window.open(newTabUrl, "_blank");
                        } else {
                          // Default behavior for normal left-click
                          window.scrollTo(0, 0);
                          navigate("/career");
                        }
                      }}
                    >
                      Careers
                    </button>
                  </div>
                  <div className="SecondList">
                    <button
                      className="quick-link"
                      onClick={(event) => {
                        if (
                          event.ctrlKey ||
                          event.metaKey ||
                          event.button === 1
                        ) {
                          const newTabUrl = "/";
                          window.open(newTabUrl, "_blank");
                        } else {
                          window.scrollTo(0, 0);
                          navigate("/");
                        }
                      }}
                    >
                      Team
                    </button>
                    <button
                      className="quick-link"
                      onClick={(event) => {
                        if (
                          event.ctrlKey ||
                          event.metaKey ||
                          event.button === 1
                        ) {
                          // If Ctrl (or Command on macOS) or middle mouse button is clicked

                          const newTabUrl = "/Portfolio";
                          window.open(newTabUrl, "_blank");
                        } else {
                          // Default behavior for normal left-click
                          window.scrollTo(0, 0);
                          navigate("/Portfolio");
                        }
                      }}
                    >
                      Projects
                    </button>
                    <button
                      className="quick-link"
                      onClick={(event) => {
                        if (
                          event.ctrlKey ||
                          event.metaKey ||
                          event.button === 1
                        ) {
                          // If Ctrl (or Command on macOS) or middle mouse button is clicked

                          const newTabUrl = "/NewPortfolio";
                          window.open(newTabUrl, "_blank");
                        } else {
                          // Default behavior for normal left-click
                          window.scrollTo(0, 0);
                          navigate("/NewPoftFolioPage");
                        }
                      }}
                    >
                      Featured Projects
                    </button>
                    <button
                      className="quick-link"
                      onClick={(event) => {
                        if (
                          event.ctrlKey ||
                          event.metaKey ||
                          event.button === 1
                        ) {
                          // If Ctrl (or Command on macOS) or middle mouse button is clicked

                          const newTabUrl = "/blog";
                          window.open(newTabUrl, "_blank");
                        } else {
                          // Default behavior for normal left-click
                          window.scrollTo(0, 0);
                          navigate("/blog");
                        }
                      }}
                    >
                      Resource Center
                    </button>
                  </div>
                </div>
              </div>
              <div className="quick-links ">
                <h4>Our Services</h4>
                <div className="linkListOfFooter">
                  <div className="SecondList">
                    <button
                      className="quick-link"
                      onClick={(event) => {
                        if (
                          event.ctrlKey ||
                          event.metaKey ||
                          event.button === 1
                        ) {
                          // If Ctrl (or Command on macOS) or middle mouse button is clicked

                          const newTabUrl = "/marketing-service";
                          window.open(newTabUrl, "_blank");
                        } else {
                          // Default behavior for normal left-click
                          window.scrollTo(0, 0);
                          navigate("/marketing-service");
                        }
                      }}
                    >
                      Marketing Solutions
                    </button>

                    <button
                      className="quick-link"
                      onClick={(event) => {
                        if (
                          event.ctrlKey ||
                          event.metaKey ||
                          event.button === 1
                        ) {
                          // If Ctrl (or Command on macOS) or middle mouse button is clicked

                          const newTabUrl = "/MediaService";
                          window.open(newTabUrl, "_blank");
                        } else {
                          // Default behavior for normal left-click
                          window.scrollTo(0, 0);
                          navigate("/MediaService");
                        }
                      }}
                    >
                      Media Solutions
                    </button>

                    <button
                      className="quick-link"
                      onClick={(event) => {
                        if (
                          event.ctrlKey ||
                          event.metaKey ||
                          event.button === 1
                        ) {
                          // If Ctrl (or Command on macOS) or middle mouse button is clicked

                          const newTabUrl = "/techservice";
                          window.open(newTabUrl, "_blank");
                        } else {
                          // Default behavior for normal left-click
                          window.scrollTo(0, 0);
                          navigate("/techservice");
                        }
                      }}
                    >
                      Tech Solutions
                    </button>

                    <button
                      className="quick-link"
                      onClick={(event) => {
                        if (
                          event.ctrlKey ||
                          event.metaKey ||
                          event.button === 1
                        ) {
                          // If Ctrl (or Command on macOS) or middle mouse button is clicked

                          const newTabUrl = "/brand";
                          window.open(newTabUrl, "_blank");
                        } else {
                          // Default behavior for normal left-click
                          window.scrollTo(0, 0);
                          navigate("/brand");
                        }
                      }}
                    >
                      Brand Solutions
                    </button>

                    <button
                      className="quick-link"
                      onClick={(event) => {
                        if (
                          event.ctrlKey ||
                          event.metaKey ||
                          event.button === 1
                        ) {
                          // If Ctrl (or Command on macOS) or middle mouse button is clicked

                          const newTabUrl = "/strategy-service";
                          window.open(newTabUrl, "_blank");
                        } else {
                          // Default behavior for normal left-click
                          window.scrollTo(0, 0);
                          navigate("/strategy-service");
                        }
                      }}
                    >
                      Strategy Solutions
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="address-section">
              <h6>Head Office</h6>
              <p className="address">
                Lower Ground Floor, Vishvadarshan Building, Plot no. 50, AB
                Road,{" "}
              </p>
              <p className="c21">
                behind C21, PU 4, Near Vijay Nagar Square, Malviya Nagar.
              </p>
              <h6 className="mt-3">Registered Office</h6>
              <p className="address">
                2nd Floor, 58 Anurag Nagar, 452001, Indore, Madhya Pradesh -
                452010.
              </p>

              {/* <p className="indore">Indore, Madhya Pradesh - 452010</p> */}
              <p className="email">connect@ehawkers.co.in </p>
              <p className="mobile-number">+91 - 98266 17764</p>
            </div>
          </div>
        </div>

        <div className="copyright-section">
          <p className="copyright-content">
            &copy; eHawkers Marketing LLP 2024
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
