import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import Footer from "./compenents/Footer/Footer";
import ServicePage from "./pages/ServicePage/ServicePage";
import Careers from "./pages/CareersPage/Careers";
import AboutPage from "./pages/AboutPage/AboutPage";
import Contact from "./pages/Contact/Contact";
import BrandPage from "./pages/BrandPage/BrandPage";
import BlogPage from "./pages/BlogPage/BlogPage";
import MarketingService from "./pages/MarketingService/MarketingService";
import StrategyService from "./pages/StrategyService/StrategyService";
import TechService from "./pages/TechService/TechService";
import MediaService from "./pages/MediaService/MediaService";
import SingleJobDetails from "./compenents/SingleJobDetails/SingleJobDetails";
import Portfolio from "./pages/Portfolio/Portfolio";
import SingleBlogDetails from "./compenents/SingleBlogDetails/SingleBlogDetails";
import MixblendCursor from "./compenents/MixblendCursor/MixblendCursor";
import NewPortfolio from "./pages/NewPortfolio/NewPortfolio";
import NewPage from "./compenents/NewPage/NewPage";

function App() {
  return (
    <div className="app">
      <Router>
        {/* <div className="curso"> */}
        <MixblendCursor />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/service" element={<ServicePage />} />
          <Route path="/career" element={<Careers />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/brand" element={<BrandPage />} />
          <Route path="/Portfolio" element={<Portfolio />} />
          <Route path="/NewPortfolio" element={<NewPortfolio />} />
          <Route path="/NewPoftFolioPage" element={<NewPage />} />
          <Route path="/techservice" element={<TechService />} />
          <Route path="/MediaService" element={<MediaService />} />
          <Route path="/marketing-service" element={<MarketingService />} />
          <Route path="/strategy-service" element={<StrategyService />} />
          <Route path="/SingleJobPost/:id" element={<SingleJobDetails />} />
          <Route path="/blog/:id" element={<SingleBlogDetails />} />
        </Routes>
        <Footer />
      </Router>
      {/* <div className="curso">
        <MixblendCursor />
      </div> */}
    </div>
  );
}

export default App;
