// new img
import screenshot51 from "../assets/images/newPortfolio/kk/Screenshot (51).png";
import screenshot52 from "../assets/images/newPortfolio/kk/Screenshot (52).png";
import screenshot53 from "../assets/images/newPortfolio/kk/Screenshot (53).png";
import screenshot54 from "../assets/images/newPortfolio/kk/Screenshot (54).png";
import screenshot55 from "../assets/images/newPortfolio/kk/Screenshot (55).png";
import screenshot56 from "../assets/images/newPortfolio/kk/Screenshot (56).png";
import screenshot57 from "../assets/images/newPortfolio/kk/Screenshot (57).png";
import screenshot58 from "../assets/images/newPortfolio/kk/Screenshot (58).png";
import screenshot59 from "../assets/images/newPortfolio/kk/Screenshot (59).png";
import screenshot60 from "../assets/images/newPortfolio/kk/Screenshot (60).png";
import screenshot61 from "../assets/images/newPortfolio/kk/Screenshot (61).png";
import screenshot62 from "../assets/images/newPortfolio/kk/Screenshot (62).png";
import screenshot63 from "../assets/images/newPortfolio/kk/Screenshot (63).png";
import screenshot64 from "../assets/images/newPortfolio/kk/Screenshot (64).png";
import screenshot65 from "../assets/images/newPortfolio/kk/Screenshot (65).png";
import screenshot66 from "../assets/images/newPortfolio/kk/Screenshot (66).png";
import screenshot67 from "../assets/images/newPortfolio/kk/Screenshot (67).png";
import screenshot68 from "../assets/images/newPortfolio/kk/Screenshot (68).png";
import screenshot69 from "../assets/images/newPortfolio/kk/Screenshot (69).png";
import screenshot70 from "../assets/images/newPortfolio/kk/Screenshot (70).png";
import screenshot71 from "../assets/images/newPortfolio/kk/Screenshot (71).png";
import screenshot72 from "../assets/images/newPortfolio/kk/Screenshot (72).png";
import screenshot73 from "../assets/images/newPortfolio/kk/Screenshot (73).png";
import screenshot74 from "../assets/images/newPortfolio/kk/Screenshot (74).png";
import screenshot75 from "../assets/images/newPortfolio/kk/Screenshot (75).png";
import screenshot76 from "../assets/images/newPortfolio/kk/Screenshot (76).png";
import screenshot77 from "../assets/images/newPortfolio/kk/Screenshot (77).png";
import screenshot78 from "../assets/images/newPortfolio/kk/Screenshot (78).png";
import screenshot79 from "../assets/images/newPortfolio/kk/Screenshot (79).png";
import screenshot80 from "../assets/images/newPortfolio/kk/Screenshot (80).png";
import screenshot81 from "../assets/images/newPortfolio/kk/Screenshot (81).png";
import screenshot82 from "../assets/images/newPortfolio/kk/Screenshot (82).png";
import screenshot83 from "../assets/images/newPortfolio/kk/Screenshot (83).png";
import screenshot84 from "../assets/images/newPortfolio/kk/Screenshot (84).png";
import screenshot85 from "../assets/images/newPortfolio/kk/Screenshot (85).png";
import screenshot86 from "../assets/images/newPortfolio/kk/Screenshot (86).png";
import screenshot87 from "../assets/images/newPortfolio/kk/Screenshot (87).png";
import screenshot88 from "../assets/images/newPortfolio/kk/Screenshot (88).png";
import screenshot89 from "../assets/images/newPortfolio/kk/Screenshot (89).png";
import screenshot90 from "../assets/images/newPortfolio/kk/Screenshot (90).png";
import screenshot91 from "../assets/images/newPortfolio/kk/Screenshot (91).png";
import screenshot92 from "../assets/images/newPortfolio/kk/Screenshot (92).png";
import screenshot93 from "../assets/images/newPortfolio/kk/Screenshot (93).png";
import screenshot94 from "../assets/images/newPortfolio/kk/Screenshot (94).png";
import screenshot95 from "../assets/images/newPortfolio/kk/Screenshot (95).png";
import screenshot96 from "../assets/images/newPortfolio/kk/Screenshot (96).png";
import screenshot97 from "../assets/images/newPortfolio/kk/Screenshot (97).png";
import screenshot98 from "../assets/images/newPortfolio/kk/Screenshot (98).png";
import screenshot99 from "../assets/images/newPortfolio/kk/Screenshot (99).png";
import screenshot100 from "../assets/images/newPortfolio/kk/Screenshot (100).png";
import screenshot101 from "../assets/images/newPortfolio/kk/Screenshot (101).png";
import screenshot102 from "../assets/images/newPortfolio/kk/Screenshot (102).png";
import screenshot103 from "../assets/images/newPortfolio/kk/Screenshot (103).png";
import screenshot104 from "../assets/images/newPortfolio/kk/Screenshot (104).png";
import screenshot105 from "../assets/images/newPortfolio/kk/Screenshot (105).png";
import screenshot106 from "../assets/images/newPortfolio/kk/Screenshot (106).png";
import screenshot107 from "../assets/images/newPortfolio/kk/Screenshot (107).png";
import screenshot108 from "../assets/images/newPortfolio/kk/Screenshot (108).png";
import screenshot109 from "../assets/images/newPortfolio/kk/Screenshot (109).png";
import screenshot110 from "../assets/images/newPortfolio/kk/Screenshot (110).png";
import screenshot111 from "../assets/images/newPortfolio/kk/Screenshot (111).png";
import screenshot112 from "../assets/images/newPortfolio/kk/Screenshot (112).png";
import screenshot113 from "../assets/images/newPortfolio/kk/Screenshot (113).png";
import screenshot114 from "../assets/images/newPortfolio/kk/Screenshot (114).png";
import screenshot115 from "../assets/images/newPortfolio/kk/Screenshot (115).png";
import screenshot116 from "../assets/images/newPortfolio/kk/Screenshot (116).png";
import screenshot117 from "../assets/images/newPortfolio/kk/Screenshot (117).png";
import screenshot118 from "../assets/images/newPortfolio/kk/Screenshot (118).png";
import screenshot119 from "../assets/images/newPortfolio/kk/Screenshot (119).png";
import screenshot120 from "../assets/images/newPortfolio/kk/Screenshot (120).png";
import screenshot121 from "../assets/images/newPortfolio/kk/Screenshot (121).png";
import screenshot122 from "../assets/images/newPortfolio/kk/Screenshot (122).png";
import screenshot123 from "../assets/images/newPortfolio/kk/Screenshot (123).png";
import screenshot124 from "../assets/images/newPortfolio/kk/Screenshot (124).png";
import screenshot125 from "../assets/images/newPortfolio/kk/Screenshot (125).png";
import screenshot126 from "../assets/images/newPortfolio/kk/Screenshot (126).png";
import screenshot127 from "../assets/images/newPortfolio/kk/Screenshot (127).png";
import screenshot128 from "../assets/images/newPortfolio/kk/Screenshot (128).png";
import screenshot129 from "../assets/images/newPortfolio/kk/Screenshot (129).png";
import screenshot130 from "../assets/images/newPortfolio/kk/Screenshot (130).png";
import screenshot131 from "../assets/images/newPortfolio/kk/Screenshot (131).png";
import screenshot132 from "../assets/images/newPortfolio/kk/Screenshot (132).png";
import screenshot133 from "../assets/images/newPortfolio/kk/Screenshot (133).png";
import screenshot134 from "../assets/images/newPortfolio/kk/Screenshot (134).png";
import screenshot135 from "../assets/images/newPortfolio/kk/Screenshot (135).png";
import screenshot136 from "../assets/images/newPortfolio/kk/Screenshot (136).png";
import screenshot137 from "../assets/images/newPortfolio/kk/Screenshot (137).png";
import screenshot138 from "../assets/images/newPortfolio/kk/Screenshot (138).png";
import screenshot139 from "../assets/images/newPortfolio/kk/Screenshot (139).png";
import screenshot140 from "../assets/images/newPortfolio/kk/Screenshot (140).png";
import screenshot141 from "../assets/images/newPortfolio/kk/Screenshot (141).png";
import screenshot142 from "../assets/images/newPortfolio/kk/Screenshot (142).png";
import screenshot143 from "../assets/images/newPortfolio/kk/Screenshot (143).png";
import screenshot144 from "../assets/images/newPortfolio/kk/Screenshot (144).png";
import screenshot145 from "../assets/images/newPortfolio/kk/Screenshot (145).png";
import screenshot146 from "../assets/images/newPortfolio/kk/Screenshot (146).png";
import screenshot147 from "../assets/images/newPortfolio/kk/Screenshot (147).png";
import screenshot148 from "../assets/images/newPortfolio/kk/Screenshot (148).png";
import screenshot149 from "../assets/images/newPortfolio/kk/Screenshot (149).png";
import screenshot150 from "../assets/images/newPortfolio/kk/Screenshot (150).png";
import screenshot151 from "../assets/images/newPortfolio/kk/Screenshot (151).png";
import screenshot152 from "../assets/images/newPortfolio/kk/Screenshot (152).png";
import screenshot153 from "../assets/images/newPortfolio/kk/Screenshot (153).png";
import screenshot154 from "../assets/images/newPortfolio/kk/Screenshot (154).png";
import screenshot155 from "../assets/images/newPortfolio/kk/Screenshot (155).png";
import screenshot156 from "../assets/images/newPortfolio/kk/Screenshot (156).png";
import screenshot157 from "../assets/images/newPortfolio/kk/Screenshot (157).png";
import screenshot158 from "../assets/images/newPortfolio/kk/Screenshot (158).png";
import screenshot159 from "../assets/images/newPortfolio/kk/Screenshot (159).png";
import screenshot160 from "../assets/images/newPortfolio/kk/Screenshot (160).png";
import screenshot161 from "../assets/images/newPortfolio/kk/Screenshot (161).png";
import screenshot162 from "../assets/images/newPortfolio/kk/Screenshot (162).png";
import screenshot163 from "../assets/images/newPortfolio/kk/Screenshot (163).png";
import screenshot164 from "../assets/images/newPortfolio/kk/Screenshot (164).png";
import screenshot165 from "../assets/images/newPortfolio/kk/Screenshot (165).png";
import screenshot166 from "../assets/images/newPortfolio/kk/Screenshot (166).png";
import screenshot167 from "../assets/images/newPortfolio/kk/Screenshot (167).png";
//
import screenshot168 from "../assets/images/newPortfolio/kk/Screenshot (168).png";
import screenshot169 from "../assets/images/newPortfolio/kk/Screenshot (169).png";
import screenshot170 from "../assets/images/newPortfolio/kk/Screenshot (170).png";
import screenshot171 from "../assets/images/newPortfolio/kk/Screenshot (171).png";
import screenshot172 from "../assets/images/newPortfolio/kk/Screenshot (172).png";
import screenshot173 from "../assets/images/newPortfolio/kk/Screenshot (173).png";
import screenshot174 from "../assets/images/newPortfolio/kk/Screenshot (174).png";
import screenshot175 from "../assets/images/newPortfolio/kk/Screenshot (175).png";
import screenshot176 from "../assets/images/newPortfolio/kk/Screenshot (176).png";
import screenshot177 from "../assets/images/newPortfolio/kk/Screenshot (177).png";
import screenshot178 from "../assets/images/newPortfolio/kk/Screenshot (178).png";
import screenshot179 from "../assets/images/newPortfolio/kk/Screenshot (179).png";
import screenshot180 from "../assets/images/newPortfolio/kk/Screenshot (180).png";
import screenshot181 from "../assets/images/newPortfolio/kk/Screenshot (181).png";
import screenshot182 from "../assets/images/newPortfolio/kk/Screenshot (182).png";
import screenshot183 from "../assets/images/newPortfolio/kk/Screenshot (183).png";
//
import screenshot185 from "../assets/images/newPortfolio/kk/Screenshot (185).png";
import screenshot186 from "../assets/images/newPortfolio/kk/Screenshot (186).png";
import screenshot187 from "../assets/images/newPortfolio/kk/Screenshot (187).png";
import screenshot188 from "../assets/images/newPortfolio/kk/Screenshot (188).png";
import screenshot189 from "../assets/images/newPortfolio/kk/Screenshot (189).png";
import screenshot190 from "../assets/images/newPortfolio/kk/Screenshot (190).png";
import screenshot191 from "../assets/images/newPortfolio/kk/Screenshot (191).png";
import screenshot192 from "../assets/images/newPortfolio/kk/Screenshot (192).png";
import screenshot193 from "../assets/images/newPortfolio/kk/Screenshot (193).png";
import screenshot194 from "../assets/images/newPortfolio/kk/Screenshot (194).png";
import screenshot195 from "../assets/images/newPortfolio/kk/Screenshot (195).png";

// import screenshot195 from "../assets/images/newPortfolio/kk/Screenshot (195).png";
import screenshot196 from "../assets/images/newPortfolio/kk/Screenshot (196).png";
import screenshot197 from "../assets/images/newPortfolio/kk/Screenshot (197).png";
import screenshot198 from "../assets/images/newPortfolio/kk/Screenshot (198).png";
import screenshot199 from "../assets/images/newPortfolio/kk/Screenshot (199).png";
import screenshot200 from "../assets/images/newPortfolio/kk/Screenshot (200).png";
import screenshot201 from "../assets/images/newPortfolio/kk/Screenshot (201).png";
import screenshot202 from "../assets/images/newPortfolio/kk/Screenshot (202).png";
import screenshot203 from "../assets/images/newPortfolio/kk/Screenshot (203).png";

export const projects = [
  // fenton
  {
    bg: "#008EDB",
    name: "Fenton Chemicals",
    description: "Corporate Website Development | Completed: September 2024",
    features: [
      "Professional Corporate Website",
      "Lead Capture System",
      "Sample Request E-commerce Flow",
      "Product Pages with Images & Descriptions",
      "Admin Dashboard",
      "WhatsApp Chatbot Support",
      "Knowledge Centre & FAQs",
      "Mobile-Responsive Design",
    ],
    techStack: [
      "React.js (Frontend)",
      "Express.js & Node.js (Backend)",
      "MongoDB (Database)",
      "Render (Backend Deployment)",
      "Vercel (Frontend Deployment)",
      "AWS S3 (Image Storage)",
      "Git (Code Management)",
    ],
    feedback:
      "eHawkers Marketing created a beautiful, user-friendly website that represents our brand. The platform is straightforward to use, and the integrated lead gathering system has greatly increased customer relations.",
    author: "— Fenton Chemicals CEO",
    contactLink: "/contact",
    projectLink: "https://fantom-e.vercel.app/",
    images: [
      screenshot86,
      screenshot87,
      screenshot88,
      screenshot89,
      screenshot90,
      screenshot91,
      screenshot92,
      screenshot93,
    ],
  },
  //aurbo
  {
    bg: "#BFFBEF",
    name: "Aurbo",
    description:
      "The Aurbo Portfolio Website is a high-performance, fully responsive, and SEO-optimized platform designed to showcase Aurbo’s product range effectively. With category-wise browsing, advanced search, and filtering, users can easily explore products. The platform includes a secure admin dashboard for seamless product management, a user inquiry system with automated notifications, and a robust indexing mechanism for improved search engine visibility. Built on the MERN stack, the website ensures scalability, speed, and an intuitive user experience.",
    features: [
      "Category-wise Product Display with Real-Time Search & Filtering",
      "Comprehensive SEO Optimization & Indexing for Enhanced Visibility",
      "Secure Admin Dashboard with Role-Based Access Control",
      "Efficient CRUD Operations for Product & Content Management",
      "Integrated User Inquiry Form with Automated Email Alerts",
      "Mobile-Optimized & Fast Page Loading",
      "AWS S3 Integration for Secure Media & Image Storage",
      "Google Analytics for Traffic & Performance Tracking",
      "SSL Encryption & Secure Hosting to Protect User Data",
      "Multi-Language Support for a Global Audience",
      "Dark & Light Mode Toggle for Improved User Experience",
      "Social Media Integration for Easy Product Sharing",
    ],
    techStack: [
      "React.js (Frontend)",
      "Node.js & Express.js (Backend)",
      "MongoDB (Database)",
      "AWS S3 (Secure Image Storage)",
      "Google Analytics (Performance Tracking)",
      "SEO Optimization Tools",
      "Vercel (Frontend Hosting)",
      "Render (Backend Hosting)",
    ],
    feedback:
      "The Aurbo Portfolio Website has significantly improved our online presence and customer engagement. With its seamless UI and powerful backend management, our team can efficiently handle product updates and inquiries. eHawkers' development team ensured top-notch quality and performance.",
    author: "eHawkers Development Team",
    contactLink: "https://aurbo.in/",
    images: [
      screenshot60,
      screenshot61,
      screenshot62,
      screenshot63,
      screenshot64,
      screenshot65,
      screenshot66,
      screenshot67,
    ],
  },
  // revoral
  {
    bg: " aqua",
    name: "Revoral",
    description: "E-commerce Platform",
    features: [
      "Modern & Responsive E-commerce Website",
      "User Authentication & Profile Management",
      "Seamless Checkout Process",
      "Add to Cart & Remove from Cart Functionality",
      "Wishlist & Favorites Management",
      "Product Pages with High-Quality Images & Detailed Descriptions",
      "Order Tracking & Management System",
      "Secure Online Payment Integration",
      "Admin Dashboard for Inventory & Order Management",
      "Automated Invoice Generation",
      "WhatsApp Chatbot Support",
      "Knowledge Centre & FAQs",
    ],
    techStack: [
      "React.js (Frontend)",
      "Express.js & Node.js (Backend)",
      "MongoDB (Database)",
      "Render (Backend Deployment)",
      "Vercel (Frontend Deployment)",
      "AWS S3 (Image Storage)",
      "Git (Code Management)",
    ],
    feedback:
      "Revoral new platform has increased our online sales by 300%! The user experience is fantastic, and managing our inventory has never been easier.",
    author: "— Revoral CEO",
    contactLink: "/contact",
    projectLink: "https://revoral.in/",
    images: [
      screenshot52,
      screenshot53,
      screenshot54,
      screenshot55,
      screenshot56,
      screenshot57,
      screenshot58,
      screenshot59,
    ],
  },
  // TEMPUS
  {
    bg: "aqua",
    name: "TEMPUS",
    description:
      "TEMPUS is a fully responsive and user-friendly e-commerce platform designed to showcase a wide range of products with an intuitive and seamless browsing experience. The platform offers a powerful admin dashboard, enabling clients to effortlessly manage products, track inventory, and update business details in real time. With advanced search and filtering options, customers can easily find what they need, while a smooth checkout process enhances conversions. Integrated chatbot support ensures quick assistance, making the platform both efficient and customer-centric.",
    features: [
      "Fully Responsive E-commerce Platform",
      "Product Catalog with Advanced Filtering",
      "Add to Cart & Wishlist Functionality",
      "Secure Checkout & Payment Integration",
      "User Authentication & Profile Management",
      "Order Tracking & Management",
      "Admin Dashboard for Easy Product & Inventory Management",
      "Automated Invoice Generation",
      "WhatsApp Chatbot Support",
      "Knowledge Centre & FAQs",
      "SEO-Optimized & Fast Loading",
      "Multi-Category & Subcategory Support",
      "Customer Reviews & Ratings",
      "Promotional Banners & Discount Management",
    ],
    techStack: [
      "React.js (Frontend)",
      "Express.js & Node.js (Backend)",
      "MongoDB (Database)",
      "Render (Backend Deployment)",
      "Vercel (Frontend Deployment)",
      "AWS S3 (Image Storage)",
      "Git (Code Management)",
    ],
    feedback:
      "TEMPUS new platform has increased our online sales by 300%! The user experience is fantastic, and managing our inventory has never been easier.",
    author: "— TEMPUS CEO",
    contactLink: "/contact",
    projectLink: "https://TEMPUS.in/",
    images: [
      screenshot94,
      screenshot95,
      screenshot96,
      screenshot97,
      screenshot98,
      screenshot99,
      screenshot100,
      screenshot101,
      screenshot102,
      screenshot103,
    ],
  },
  // iSHEros
  {
    bg: "#E53F2D",
    name: "iSHEros",
    description:
      "iSHEros is an NGO platform empowering women with career resources, networking, and exclusive member benefits.",
    features: [
      "Membership Platform: Lifetime access to career tools, events",
      "Event Management: Webinars, training, networking",
      "Donation System: Easy contributions for social impact",
      "Branding & Social Media: Facebook, Instagram, LinkedIn campaigns",
      "Virtual Shark Tank: Entrepreneurial pitching for women",
      "Career & Financial Growth: Counseling, budgeting resources",
    ],
    feedback:
      "iSHEros has empowered thousands of women with career tools and networking opportunities, significantly enhancing their professional growth.",
    techStack: ["React", "Node.js", "MongoDB", "AWS", "Stripe"],
    author: "NGO Team",
    contactLink: "http://www.isheros.com/contact",
    images: [
      screenshot149,
      screenshot151,
      screenshot152,
      screenshot153,
      screenshot154,
      screenshot155,
      screenshot157,
      screenshot158,
    ],
  },
  // Divyta
  {
    bg: "#B5801C",
    name: "Divyta",
    description:
      "Divyta is an advanced real estate platform that helps users explore, buy, sell, and rent properties efficiently.",
    features: [
      "Property Listings: Residential, commercial, and rental properties",
      "Advanced Search: Filter by price, location, size, and more",
      "Interactive Map: Google Maps integration for property locations",
      "User Accounts: Buyers and sellers can manage profiles and inquiries",
      "SEO & Local Optimization: Increased search visibility",
      "Agent & Seller Profiles: Contact real estate professionals directly",
      "Blog Integration: Insights on home-buying and investment trends",
      "Admin Panel: Manage listings, users, and analytics",
    ],
    feedback:
      "Divyta has transformed how we connect buyers with sellers in the real estate market. The platform is easy to use and offers seamless property browsing, making the entire process more efficient.",
    techStack: [
      "WordPress with custom real estate themes",
      "HTML5",
      "CSS3",
      "JavaScript (Responsive Design)",
      "PHP",
      "MySQL (CMS Management)",
      "Google Maps API",
      "Google Analytics for SEO & keyword tracking",
    ],
    author: "Real Estate Agency Team",
    contactLink: "http://www.divyta.com/contact",
    images: [
      screenshot141,
      screenshot142,
      screenshot143,
      screenshot144,
      screenshot145,
      screenshot146,
      screenshot147,
      screenshot148,
    ],
  },
  // jet Trade
  {
    bg: "brown",
    name: "Jet Trade Logistics",
    description:
      "Jet Trade Logistics is a fully responsive and feature-rich platform designed to showcase company details, facilitate seamless customer engagement, and provide a secure dashboard for managing logistics, products, blogs, and contact inquiries. The website includes an intuitive contact form, ensuring easy communication, and a powerful backend system that allows admins to securely log in, update company details, and manage content efficiently. With advanced tracking, booking, and analytics tools, the platform enhances operational efficiency and improves the overall user experience.",
    features: [
      "Fully Responsive Corporate Website",
      "Secure Admin Dashboard with Authentication",
      "Online Service Booking System",
      "Real-time Shipment Tracking with Automated Updates",
      "Comprehensive Logistics Management Dashboard",
      "Easy Product, Blog & Contact Form Management",
      "Role-Based Access Control for Enhanced Security",
      "Data Analytics & Report Generation",
      "Automated Email & SMS Notifications",
      "Integrated Payment Gateways (Stripe & PayPal)",
      "Contact Form with Inquiry Management",
      "SEO-Optimized & Fast Loading",
      "SSL Encryption & Secure Hosting",
    ],
    techStack: [
      "HTML5",
      "CSS3",
      "JavaScript",
      "React",
      "PHP",
      "MySQL",
      "Laravel",
      "Real-time Logistics Tracking API",
      "Stripe Payment Gateway",
      "PayPal",
      "SSL Encryption & Secure Hosting",
    ],
    feedback:
      "The project resulted in a fully custom, scalable website that serves as an all-in-one platform for Jet Trade Logistics' digital needs, enhancing operational efficiency and customer satisfaction through features like online booking, real-time tracking, and a comprehensive backend management system.",
    author: "eHawkers Development Team",
    contactLink: "https://www.jettradelogistics.com/contact",
    images: [
      screenshot125,
      screenshot126,
      screenshot127,
      screenshot128,
      screenshot129,
      screenshot130,
      screenshot131,
      screenshot132,
    ],
  },
  // unipride
  {
    bg: "#3F7BD9",
    name: "Unipride Portfolio Website",
    description:
      "The Unipride Portfolio Website is a fully responsive and SEO-optimized platform designed to showcase Unipride's products in a structured and user-friendly manner. With category-wise product browsing, dynamic search, and filtering, users can easily find what they need. The website includes a secure admin dashboard for effortless content management, a user inquiry system with automated email notifications, and full indexing for better search engine visibility. Built on the MERN stack, the platform ensures scalability, speed, and a seamless browsing experience.",
    features: [
      "Category-wise Product Display with Advanced Filtering",
      "Full SEO Optimization & Indexing for Search Engines",
      "Secure Admin Dashboard with Role-Based Access Control",
      "CRUD Operations for Product & Content Management",
      "Dynamic Product Browsing with Real-Time Search",
      "User Inquiry Form with Automated Email Notifications",
      "High-Performance & Scalable Backend Architecture",
      "Mobile-Optimized & Fast-Loading Pages",
      "Integration with AWS S3 for Secure Image Storage",
      "Google Analytics & Performance Tracking",
      "SSL Encryption & Secure Hosting for Data Protection",
    ],
    techStack: [
      "React.js",
      "Node.js",
      "Express.js",
      "MongoDB",
      "AWS S3",
      "Google Analytics",
      "SEO Optimization Tools",
    ],
    feedback:
      "The Unipride Portfolio Website has streamlined our product showcase and customer inquiries, providing an intuitive and user-friendly experience. eHawkers' development team ensured every feature was built to enhance our digital presence.",
    author: "eHawkers Development Team",
    contactLink: "https://unipride.in/",
    images: [
      screenshot105,
      screenshot106,
      screenshot107,
      screenshot108,
      screenshot109,
      screenshot110,
      screenshot111,
      screenshot112,
    ],
  },
  // eHawkers Marketing Website
  {
    bg: "#0C1526",
    name: "eHawkers Marketing Website",
    description:
      "The eHawkers Marketing Website is a fully animated, interactive, and high-performance platform built to showcase eHawkers' services. Utilizing cutting-edge technologies like GSAP, Three.js, and Particle.js, the website delivers a seamless and visually captivating user experience. It includes a dynamic career page, a powerful admin dashboard for content management, and robust SEO indexing to enhance discoverability.",
    features: [
      "Advanced animations with GSAP, Three.js, and Particle.js for a dynamic visual experience",
      "Fully responsive 16-page website optimized for all devices",
      "Dynamic career page with real-time job postings and online applications",
      "SEO-optimized with structured metadata and fast indexing",
      "Secure admin dashboard with authentication for job posting and content management",
      "Interactive UI with smooth transitions and parallax effects",
      "Blog section with rich content management and social sharing",
      "Integrated contact form with automated email responses",
      "Multi-language support for broader audience reach",
      "Optimized performance with lazy loading and image compression",
      "SSL Encryption & Secure Hosting for enhanced security",
      "Google Analytics integration for visitor tracking and engagement insights",
    ],
    techStack: [
      "React.js",
      "GSAP",
      "Three.js",
      "Particle.js",
      "Node.js",
      "Express.js",
      "MongoDB",
      "SEO Optimization Tools",
      "AWS S3 (Media Storage)",
      "Google Analytics",
      "Vercel (Frontend Hosting)",
      "Render (Backend Hosting)",
    ],
    feedback:
      "The eHawkers Marketing Website exceeded our expectations with its cutting-edge animations and dynamic features. It beautifully represents our business, enhances user engagement, and simplifies career management. The eHawkers development team showcased exceptional creativity and technical expertise.",
    author: "eHawkers Development Team",
    contactLink: "https://ehawkers.co.in/",
    images: [
      screenshot76,
      screenshot77,
      screenshot78,
      screenshot79,
      screenshot80,
      screenshot81,
      screenshot82,
      screenshot83,
      screenshot84,
      screenshot85,
    ],
  },
  // Gsl steel limited
  {
    bg: "#0C1526",
    name: "GSL STEEL LIMITED",
    description:
      "GSL STEEL LIMITED is a feature-rich, interactive platform designed to showcase GSL Steel's services, including advanced animations, dynamic content, and powerful backend functionalities. The website offers a seamless user experience with a dynamic career page, interactive design, and a robust admin dashboard to manage content effectively.",
    features: [
      "High-quality animations using GSAP, Three.js, and Particle.js for immersive user experience",
      "Dynamic career page with real-time job postings, application forms, and status tracking",
      "Interactive design with 16 fully responsive pages for diverse screen sizes",
      "Secure admin dashboard for managing job postings, applications, and other content",
      "SEO-optimized for better search engine ranking and visibility",
      "Smooth navigation with enhanced performance for a faster loading experience",
      "Integrated social sharing options for broader audience engagement",
      "SSL Encryption & Secure Hosting for reliable security and data protection",
      "Automated email notifications for job application status updates",
      "Real-time chat support integration for instant customer assistance",
      "Blog section for sharing industry-related news and updates",
      "Google Analytics integration for performance tracking and audience insights",
    ],
    feedback:
      "The GSL STEEL LIMITED Website exceeded our expectations with its modern animations and cutting-edge features. It perfectly represents our business, simplifying recruitment and content management. The eHawkers development team demonstrated exceptional creativity, and the user experience is second to none.",
    techStack: [
      "React.js",
      "GSAP",
      "Three.js",
      "Particle.js",
      "Node.js",
      "Express.js",
      "MongoDB",
      "SEO Optimization Tools",
      "Google Analytics",
      "AWS S3 (Media Storage)",
    ],
    author: "GSL STEEL LIMITED",
    contactLink: "https://ehawkers.co.in/",
    images: [
      screenshot68,
      screenshot69,
      screenshot70,
      screenshot71,
      screenshot72,
      screenshot73,
      screenshot74,
      screenshot75,
    ],
  },
  //isle
  {
    name: "ISEL Portfolio Website",
    description:
      "The ISEL Portfolio Website is a modern platform designed to showcase ISEL's services, achievements, and societal contributions. The website offers a smooth and interactive user experience, along with a secure admin system for easy content management and updates. It's a fully responsive platform optimized for all devices and provides a minimalistic design with a focus on performance.",
    features: [
      "Dynamic portfolio display with sections for projects, team members, company milestones, and societal contributions",
      "User-friendly contact form for inquiries and feedback",
      "Fully responsive design optimized for desktop, tablet, and mobile devices",
      "Secure admin access to manage portfolio content, including project details and team member information",
      "Minimalistic design focused on enhancing user experience and website performance",
      "Secure user authentication for admin functionalities, ensuring restricted access to sensitive areas",
      "SEO-optimized for better online visibility",
      "Real-time notifications for new inquiries and content updates",
      "Integrated blog section to share news, updates, and industry-related articles",
      "Social media integration for wider reach and engagement",
      "Accessible design with WCAG 2.0 compliance for users with disabilities",
    ],
    feedback:
      "The ISEL Portfolio Website effectively showcases our services and societal impact with a sleek, modern design. The secure admin panel streamlines content management, and the mobile-optimized design ensures accessibility across all devices.",
    techStack: [
      "React.js",
      "Node.js",
      "Express.js",
      "MongoDB",
      "SEO Optimization Tools",
      "AWS S3 (Media Storage)",
      "Social Media APIs",
      "Google Analytics",
    ],
    author: "ISEL Development Team",
    contactLink: "https://isel.com/",
    images: [
      screenshot139,
      screenshot133,
      screenshot134,
      screenshot135,
      screenshot136,
      screenshot137,
      screenshot138,
      screenshot140,
    ],
  },
  //NAMSTETU
  {
    name: "Namastetu",
    description:
      "The Namastetu Portfolio Website is a modern platform highlighting ISEL's services and achievements. It offers a smooth user experience with a secure admin system for content updates.",
    features: [
      "Dynamic portfolio display with sections for projects, team members, and company milestones",
      "User-friendly contact form for inquiries",
      "Fully responsive design optimized for all devices",
      "Secure admin access to manage portfolio content and handle inquiries",
      "Minimalistic design with a focus on performance",
      "Secure user authentication for admin functionalities",
    ],
    feedback:
      "The Namastetu Portfolio Website effectively showcases our services with a sleek and modern design. The secure admin panel makes content updates seamless.",
    techStack: ["React.js", "Node.js", "Express.js", "MongoDB"],
    author: "Namastetu CEO",
    contactLink: "https://isel.com/",
    images: [
      screenshot114,
      screenshot115,
      screenshot116,
      screenshot117,
      screenshot118,
      screenshot119,
      screenshot120,
      screenshot121,
      screenshot122,
      screenshot123,
    ],
  },
  //Kanha Art Jewelry
  {
    bg: "blue",
    name: "Kanha Art Jewelry",
    description:
      "Branding, E-commerce Website & Social Media Campaign powered by the MERN stack and AWS for seamless scalability and performance.",
    features: [
      "Custom logo and color palette design to match brand identity",
      "Consistent brand guidelines across all platforms",
      "Fully responsive e-commerce platform built on the MERN stack with dynamic shopping cart, wishlist, and checkout functionality",
      "Secure payment integration via PhonePe for smooth transactions",
      "Add to cart and wishlist functionality with dynamic updates using Redux for state management",
      "Fully mobile-optimized design for a seamless shopping experience across devices",
      "User authentication for secure checkout and order tracking",
      "AWS Serverless deployment with S3 for storage and scalability",
      "Professional product photography & digital catalog",
      "Order tracking for customers with real-time updates",
      "In-store branding with posters, banners, and brochures",
    ],
    techStack: [
      "React.js (Frontend)",
      "Node.js (Backend)",
      "Express.js (Backend Framework)",
      "MongoDB (Database)",
      "AWS Lambda (Serverless Functionality)",
      "AWS S3 (Storage)",
      "PhonePe (Payment Integration)",
      "Redux (State Management)",
      "Adobe Photoshop (Design)",
      "Instagram, Facebook, Canva, Hootsuite (Social Media)",
    ],
    challenges: [
      {
        challenge: "Transitioning from offline to online",
        solution:
          "Developed a user-friendly e-commerce site built on the MERN stack, offering a smooth and secure shopping experience.",
      },
      {
        challenge: "Building a strong online presence",
        solution:
          "Executed engaging social media campaigns and created targeted ads to increase brand awareness.",
      },
    ],
    results: {
      salesIncrease:
        "40% growth in monthly sales due to optimized user experience and social media campaigns",
      socialMediaGrowth:
        "50% increase in engagement through targeted social media strategies",
      inStoreTraffic:
        "Boosted footfall in physical stores through targeted promotional material and online campaigns",
    },
    feedback:
      "The website and social media campaigns have significantly boosted our brand presence and sales. The transition to online business was seamless, thanks to the powerful MERN stack and AWS serverless setup.",
    author: "Kanha Art Jewelry Team",
    contactLink: "/contact",
    projectLink: "https://www.kanhaartjewelry.com/",
    futurePlans: [
      "Virtual try-on feature for customers to try jewelry online",
      "Global shipping expansion for a wider customer base",
      "Loyalty program for repeat customers to enhance customer retention",
    ],
    images: [
      screenshot196,
      screenshot197,
      screenshot198,
      screenshot199,
      screenshot200,
      screenshot201,
      screenshot202,
      screenshot203,
    ],
    // Replace with actual image references of the website and products
  },
  //rear
  {
    bg: "#DEB998",
    name: "RAER",
    description:
      "RAER, a premium men’s shirt brand, required a custom-built website to increase online sales and enhance the customer journey. eHawkers delivered a high-performance MERN stack website with a seamless e-commerce experience, dynamic shopping cart, wishlist, and advanced transaction features.",
    features: [
      "Tailored Design: Modern, sleek UI to showcase RAER’s exclusive shirt collections",
      "Performance Optimization: Hand-coded for fast load times and smooth browsing",
      "Fully Integrated Product Catalog & Shopping Cart: Dynamic shopping cart with add-to-cart functionality and seamless purchasing experience",
      "Wishlist Functionality: Customers can add products to their wishlist for easy access and purchase later",
      "Secure Payment Gateway: Integrated PhonePe for hassle-free transactions",
      "Wallet Integration: Customers can store credits for discounts and repeat purchases",
      "Automated Order Tracking: Real-time updates on purchase status and delivery",
      "One-Click Reordering: Streamlined checkout process for returning customers",
      "Fully Responsive Design: Optimized for all devices, ensuring smooth shopping experience across mobile, tablet, and desktop",
      "Admin Dashboard: Secure admin access to manage products, orders, and customer data",
      "Product Filtering & Sorting: Users can filter shirts by size, color, price range, etc.",
      "SEO Optimized: Designed with SEO in mind for better discoverability and search rankings",
    ],
    feedback:
      "The custom website significantly boosted RAER’s online sales, improved the purchasing experience with wallet integration, and streamlined the overall customer journey, leading to higher conversions and repeat customers.",
    techStack: [
      "React.js (Frontend)",
      "CSS3",
      "JavaScript",
      "Node.js (Backend)",
      "Express.js (Backend Framework)",
      "MongoDB (Database)",
      "Custom-built MERN stack for scalability and performance",
      "PhonePe Payment Integration",
      "Custom Wallet Feature for repeat customers",
      "Redux (State Management for Cart & Wishlist)",
      "AWS S3 (Storage for Product Images)",
    ],
    author: "eHawkers Development Team",
    contactLink: "https://www.raer.com/contact",
    projectLink: "https://www.raer.com",
    images: [
      screenshot168,
      screenshot169,
      screenshot170,
      screenshot171,
      screenshot172,
      screenshot173,
      screenshot174,
      screenshot175,
    ],
  },
  //Property Suchna
  {
    name: "Property Suchna",
    description:
      "Mobile application for property-related content in a social media format.",
    features: [
      "User authentication with OTP via Twilio",
      "JWT-based secure access",
      "Property posts with text, photos, and videos",
      "Like, comment, and share functionalities",
      "Profile management with image upload",
      "Real-time notifications for interactions",
      "CRUD operations for posts and comments",
      "Integration with Google News and Bing News APIs",
      "Onboarding, About Us, Help & Support, Privacy Policy, Terms & Conditions pages",
      "Messaging and call features for classified ads",
      "Search and filter for classified ads",
      "Blog section with CRUD operations",
      "Property-related services (loans, registration, insurance)",
      "Admin backend to manage services",
      "Pagination for sections like Reels, Ads, News, and Blogs",
    ],
    techStack: [
      "React Native (Mobile Frontend)",
      "Expo (App Deployment)",
      "Redux (State Management)",
      "React Navigation (App Navigation)",
      "Node.js & Express.js (Backend)",
      "MongoDB & Mongoose (Database)",
      "JWT (Authentication)",
      "Twilio (OTP Verification)",
      "Firebase (Real-time Notifications)",
    ],
    feedback:
      "Enabled a vibrant community for property discussions with social media-like features, boosting user engagement and property listings.",
    projectLink:
      "https://play.google.com/store/apps/details?id=com.propertysuchna&pcampaignid=web_share",
    images: [
      screenshot195,
      screenshot188,
      screenshot189,
      screenshot190,
      screenshot191,
      screenshot192,
      screenshot193,
      screenshot194,
    ],
    futurePlans: [
      "Advanced search by location and price",
      "User interaction analytics",
      "Property listings by real estate professionals",
    ],
  },
  //Akash Uniforms
  {
    name: "Akash Uniforms",
    description:
      "Akash Uniforms, a leading provider of uniforms for schools, hospitals, and corporate clients, required a user-friendly online store optimized for bulk orders. eHawkers developed a Shopify-based platform to streamline the shopping experience and enhance their online presence.",
    features: [
      "Custom Design: Professional, clean layout for B2B and B2C customers",
      "Product Customization: Bulk order functionality with size, color, and logo options",
      "Organized Product Categories: School, hospital, corporate, and industrial uniforms",
      "Secure Payment Gateway: Integrated PayPal, Stripe, UPI, and wallet payments",
      "Advanced Checkout: Streamlined process for small and bulk orders",
      "Order Tracking: Real-time monitoring for all purchases",
      "Customer Wallet: Allows customers to save credits for future orders",
      "Discounts & Offers: Bulk order discounts and promotional codes",
    ],
    feedback:
      "The Shopify-powered platform increased online sales, improved bulk ordering efficiency, and enhanced customer satisfaction with seamless customization and payment integration.",
    techStack: [
      "Shopify",
      "Liquid",
      "HTML",
      "CSS",
      "PayPal",
      "Stripe",
      "Wallet Integration",
    ],
    author: "eHawkers Development Team",
    contactLink: "https://www.akashuniforms.com/contact",
    images: [
      screenshot176,
      screenshot177,
      screenshot178,
      screenshot179,
      screenshot180,
      screenshot181,
      screenshot182,
      screenshot183,
    ],
  },
];
