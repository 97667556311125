import ja from "../assets/images/home/teams/01.png";
import sa from "../assets/images/home/teams/01.png";
import aa from "../assets/images/home/teams/k.png";
// import aa from "../assets/images/home/teams/03.png";
import aryan from "../assets/images/home/teams/aryan.jpg";
import tanuj from "../assets/images/home/teams/tanuj.jpg";

export const employeeData = [
  {
    id: 1,
    imgSrc: aa,
    alt: "icon..",
    name: "samriddhi acholiya",
    position: "HR Manager",
    details: "lorem lorem ipsum dolor sit amet, consectet null amet, sed diam",
    socialLinks: {
      instagram: "https://instagram.com/johndoe",
      linkedin: "https://linkedin.com/in/johndoe",
      twitter: "https://twitter.com/johndoe",
    },
  },
  {
    id: 2,
    imgSrc: aryan,
    alt: "icon..",
    name: "Aryan Patel (IIIT, DHARAWAD)",
    position: "Software Developer",
    details: "",

    socialLinks: {
      instagram: "https://instagram.com/janesmith",
      linkedin: "https://linkedin.com/in/janesmith",
      twitter: "https://twitter.com/janesmith",
    },
  },
  {
    id: 3,
    imgSrc: tanuj,
    alt: "icons...",
    name: "Tanuj Sharma",
    details: "lorem lorem ipsum dolor sit amet, consectet null amet, sed diam",

    position: "Social Media Manager",
    socialLinks: {
      instagram: "https://instagram.com/mikejohnson",
      linkedin: "https://linkedin.com/in/mikejohnson",
      twitter: "https://twitter.com/mikejohnson",
    },
  },
  // {
  //   id: 3,
  //   imgSrc: sa,
  //   alt: "icons...",
  //   name: "Satyam",
  //   details :"lorem lorem ipsum dolor sit amet, consectet null amet, sed diam",

  //   position: "UI/UX Designer",
  //   socialLinks: {
  //     instagram: "https://instagram.com/mikejohnson",
  //     linkedin: "https://linkedin.com/in/mikejohnson",
  //     twitter: "https://twitter.com/mikejohnson",
  //   },
  // },

  // Add similar objects for the rest of the employees.
];
