import React from "react";
import "./GrowthJourney.scss";

import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { ScrollSmoother } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const carditemData = [
  {
    phase: "Phase01",
    image:
      "https://ehawkers-marketing.vercel.app/static/media/letsTalk.a0bdc3253d59f6fa8018.gif",
    title: "Let’s Talk",
    content:
      "Book your call to get the ball rolling and discover how you can launch your growth to new heights.",
  },
  {
    phase: "Phase02",

    image:
      "https://ehawkers-marketing.vercel.app/static/media/gainInsight.b4e878161f87d274e1ed.gif",
    title: "Gain Custom Insights",
    content:
      "Get over data-driven insights about your business and offer new knowledge to boost your brand.",
  },
  {
    phase: "Phase03",

    image:
      "https://ehawkers-marketing.vercel.app/static/media/strategyPlan.2080487d83b4213fb04d.gif",
    title: "Strategize and Plan",
    content:
      "After you sign up with us,we get down to nitty-grittyand make an actionable plan to get results that meet your goals.",
  },
  {
    phase: "Phase04",

    image:
      "https://ehawkers-marketing.vercel.app/static/media/launch.3727ec4e700bef3ed6e4.gif",
    title: "Launch and Scale",
    content:
      "We turn the plan into action and work with you to reach audiences and turn traffic into sales",
  },
];

export default function GrowthJourney() {
  useGSAP(() => {
    if (window.innerWidth < 768) return;

    gsap.to(".card-section", {
      x: "-70%",
      scrollTrigger: {
        trigger: ".gourneycontainer",
        start: "top 10%",
        end: "bottom 0%",
        scrub: 1.5,
        pin: true,
      },
    });
  });
  useGSAP(() => {
    if (window.innerWidth < 768) return;

    gsap.to(".whiteline", {
      x: "-70%",
      scrollTrigger: {
        trigger: ".whiteline",
        start: "bottom 73.10%",
        end: "bottom -10%",
        scrub: true,
      },
    });
  });

  return (
    <div className="gourneycontainer">
      <div className="leftbg">
        <div className="innerbg"></div>
      </div>
      <div className="GrowthJourney">
        <h1>
          The Growth Journey
          <br /> Let’s do great work together
        </h1>

        <div className="cardOuter">
          <div className="card-section">
            {carditemData.map((item, id) => {
              return (
                <div className="cardcontainers">
                  <div key={id} className="card-item">
                    <div className="bgwhite"></div>

                    <img src={item.image} alt={item.title} />
                    <div className="information">
                      <h3>{item.title}</h3>
                      <p>{item.content}</p>
                    </div>
                  </div>
                  <div className="phase">{item.phase}</div>
                </div>
              );
            })}
          </div>
          {/* <div className="whiteline">
            <div className="lineOuter">
              <div className="line"></div>
              <button>Phase 01</button>
              <div className="line"></div>
            </div>
            <div className="lineOuter">
              <div className="line"></div>
              <button>Phase 02</button>
              <div className="line"></div>
            </div>
            <div className="lineOuter">
              <div className="line"></div>
              <button>Phase 03</button>
              <div className="line"></div>
            </div>
            <div className="lineOuter">
              <div className="line"></div>
              <button>Phase 04</button>
              <div className="line"></div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="rightbg"></div>
    </div>
  );
}
