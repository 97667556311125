import React, { useEffect, useState } from "react";
import "./TechServiceDetails.scss";
import { FaRegCheckCircle } from "react-icons/fa";
import hoverImage from "../../assets/images/home/fen.png";
// Data for the services
const techServices = [
  {
    number: "01",
    title: "Website <br/> Development",
    items: [
      "Custom website design for specific industries",
      "Portfolio and e-commerce websites",
      "Membership and subscription websites",
    ],
    imagepart: hoverImage,
  },
  {
    number: "02",
    title: "App <br/> Development",
    items: [
      "Enterprise-level mobile apps (iOS, Android)",
      "Educational and gaming apps",
      "Service-on-demand platforms",
    ],
    imagepart: hoverImage,
  },
  {
    number: "03",
    title: "Tech Support <br/> & Maintenance",
    items: [
      "Server management and hosting solutions",
      "Cybersecurity measures and data protection",
      "Performance monitoring and troubleshooting",
    ],
    imagepart: hoverImage,
  },
  {
    number: "04",
    title: "Cloud & <br/> Integration Services",
    items: [
      "CRM integration with sales platforms",
      "Cloud migration for business operations",
      "Custom business dashboards for insights",
    ],
    imagepart: hoverImage,
  },
];

function TechServiceDetails() {
  const [hovered, setHovered] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const checkScreenWidth = () => {
    if (window.innerWidth <= 1200) {
      setHovered(true);
    } else {
      setHovered(false);
    }
  };
  // Run check on mount & whenever window resizes
  useEffect(() => {
    checkScreenWidth(); 
    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  const handleMouseMove = (e) => {
    if (window.innerWidth <= 768) {
      return;
    }
    e.preventDefault();
    const { left, top } = e.currentTarget.getBoundingClientRect();
    setPosition({
      x: e.clientX - left,
      y: e.clientY - top,
    });
  };
  return (
    <div className="tech-list-main">
      {techServices.map((service) => (
        <div
          className="single-tech-outer"
          key={service.number}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onMouseMove={handleMouseMove}
        >
          <div className="single-tech-inner">
            <div className="whitebgoverlay">
              <div className="white-glow-bg"></div>
            </div>
            <div className="tech-list-number">
              <p className="list-single-number">&lt;{service.number}/&gt;</p>
            </div>
            <div className="tech-list-heading">
              <p
                className="single-heading"
                dangerouslySetInnerHTML={{ __html: service.title }}
              ></p>
            </div>
            {/* Image that follows the cursor */}
            {hovered && (
              <div
                className="hover-overlay"
                style={{
                  position: "absolute",
                  left: `${position.x}px`,
                  top: `${position.y}px`,
                  top: "-29px",
                  zIndex: "-1",
                  transform: "translate(-40%, -50%)",
                  pointerEvents: "none",
                  transition: "transform 0.1s linear",
                }}
              >
                <img src={service.imagepart} alt="" className="imageOnHover" />
              </div>
            )}
            <div className="tech-list-content">
              {service.items.map((item, index) => (
                <div className="tech-content-inner" key={index}>
                  <FaRegCheckCircle className="circle-icon" />
                  <p className="tech-para">{item}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
export default TechServiceDetails;
