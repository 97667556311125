import React from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useNavigate } from "react-router-dom";
import "./OurServices.scss";
import { serviceData } from "../../Constants/ServiceDetails";

gsap.registerPlugin(ScrollTrigger);

export default function OurServices() {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (window.innerWidth < 768) return;
    gsap.to(".service-cards-container", {
      x: "-59%",
      scrollTrigger: {
        trigger: ".serviceCard",
        start: "top 0%",
        scrub: 1,
      },
    });
  }, []);

  return (
    <div className="OurServices">
      <h1 className="text-center">Explore our Services</h1>
      <p className="inner-des">
        Crafting Brands, Driving Success, Comprehensive Marketing and Technical
        Solutions <br /> for Lasting Online Relationships and Revenue Growth
      </p>

      <div className="serviceCard">
        <div className="service-cards-container">
          {serviceData.map((service, index) => (
            <div
              className="service-card"
              key={index}
              onClick={(event) => {
                if (event.ctrlKey || event.metaKey || event.button === 1) {
                  const newTabUrl = `${service.link}`;
                  window.open(newTabUrl, "_blank");
                } else {
                  window.scrollTo(0, 0);
                  navigate(`${service.link}`);
                }
              }}
            >
              <video autoPlay loop muted className="video">
                <source src={service.image} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="cardInfo">
                <div className="uperbg" style={{ background: service.uperbg }}>
                  <div className="centerbg">
                    <div
                      className="bottombg"
                      style={{ background: service.bottombg }}
                    ></div>
                  </div>
                </div>
                <h3>
                  {service.heading.split(" & ").map((part, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {part}
                      {index === 0 && "  "}
                    </React.Fragment>
                  ))}
                </h3>

                <ul>
                  {service.department.map((dept, i) => (
                    <li key={i}>{dept}</li>
                  ))}
                </ul>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

// import React, { useEffect, useRef } from "react";
// import "./OurServices.scss";
// import { serviceData } from "../../Constants/ServiceDetails";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { useGSAP } from "@gsap/react";
// import { ScrollSmoother } from "gsap/all";
// import { useNavigate } from "react-router-dom";

// gsap.registerPlugin(ScrollTrigger);

// export default function OurServices() {
//   const navigate = useNavigate();
//   gsap.registerPlugin(ScrollTrigger);

//   useGSAP(() => {
//     gsap.to(".service-cards-container ", {
//       x: "-59%",
//       scrollTrigger: {
//         trigger: ".serviceCard",
//         start: "top 0%",
//         scrub: 1,
//         markers: true,
//       },
//     });
//   });

//   return (
//     <div className="OurServices">
//       <h1 className="text-center">Explore our Services</h1>
//       <p className="inner-des">
//         Crafting Brands, Driving Success, Comprehensive Marketing and Technical
//         Solutions <br /> for Lasting Online Relationships and Revenue Growth
//       </p>

//       <div className="serviceCard">
//         <div className="service-cards-container">
//           {serviceData.map((service, index) => (
//             <div
//               className="service-card"
//               key={index}
//               onClick={(event) => {
//                 if (event.ctrlKey || event.metaKey || event.button === 1) {
//                   const newTabUrl = `${service.link}`;
//                   window.open(newTabUrl, "_blank");
//                 } else {
//                   window.scrollTo(0, 0);
//                   navigate(`${service.link}`);
//                 }
//               }}
//             >
//               <video autoPlay loop muted className="video">
//                 <source src={service.image} type="video/mp4" />
//                 Your browser does not support the video tag.
//               </video>
//               <div className="cardInfo">
//                 <div className="uperbg" style={{ background: service.uperbg }}>
//                   <div className="centerbg">
//                     <div
//                       className="bottombg"
//                       style={{ background: service.bottombg }}
//                     ></div>
//                   </div>
//                 </div>
//                 <h3>
//                   {service.heading.split(" & ").map((part, index) => (
//                     <React.Fragment key={index}>
//                       {index > 0 && <br />}
//                       {part}
//                       {index === 0 && "  "}
//                     </React.Fragment>
//                   ))}
//                 </h3>

//                 <ul>
//                   {service.department.map((dept, i) => (
//                     <li key={i}>{dept}</li>
//                   ))}
//                 </ul>
//                 <p>{service.description}</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }
